'use client'

import { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import {
  Button,
  Heading,
  Flex,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  FormControl,
  FormLabel,
  Input,
  Text,
  Badge,
  Icon,
  Progress,
  Link,
  RadioGroup,
  Radio
} from '@chakra-ui/react';
import { FiArrowLeft, FiRotateCw, FiExternalLink } from 'react-icons/fi';
import { WiStars } from 'react-icons/wi';
import { useGeneralContext } from '../../App';
import { getErrorMessage } from '../../utils/utils';
import { PROCESS_STATUS_LIST, PSE_COLUMNS, OSE_COLUMNS, DSE_COLUMNS, CTE_COLUMNS, WP_API_KEY } from '../../utils/constants';
import loadWoodpeckerContacts from '../woodpecker/utils';

export const PipeDriveContacts = () => {
  const [googlesheetId, setGooglesheetId] = useState<string>('');
  const [sheetName, setSheetName] = useState<string>('');
  const [apiKey, setApiKey] = useState<string>('');
  const [includeReplies, setIncludeReplies] = useState<string>('no');

  const [fileColumns, setFileColumns] = useState<Array<any>>([]);
  const [fileData, setFileData] = useState<Array<any>>([]);
  const [prospectsList, setProspectsList] = useState<Array<any>>([]);
  const [woodpeckerContacts, setWoodpeckerContacts] = useState<Array<any>>([]);
  const [campaignsList, setCampaignsList] = useState<any>({});
  const [labelOptions, setLabelOptions] = useState<any>({});
  const [personFields, setPersonFields] = useState<any>({});
  const [currentStep, setCurrentStep] = useState(0);
  const [processLog, setProcessLog] = useState<Array<any>>([]);
  const [processResults, setProcessResults] = useState<any>({
    total: 0,
    processed: 0,
    excluded: 0,
    newOrgs: 0,
    newPersons: 0,
    updatedPersons: 0,
    wpTotal: 0,
    duplicates: 0,
    lookupErrors: 0,
    rejectedContacts: 0,
    newProspects: 0,
    loadedContacts: 0
  });
  const [configData, setConfigData] = useState<any>({});

  const [isReadyToProcess, setIsReadyToProcess] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [hasFinished, setHasFinished] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { accessToken } = useGeneralContext();

  useEffect(() => {
    setIsReadyToProcess(Boolean(googlesheetId && sheetName && apiKey));
  }, [googlesheetId, sheetName, apiKey]);

  useEffect(() => {
    if (isProcessing && !errorMessage && currentStep < steps.length) {
      executeStep();
    } else if (currentStep >= steps.length) {
      const timeout = setTimeout(() => {
        setIsProcessing(false);
        setHasFinished(true);
      }, 1000);
  
      return () => clearTimeout(timeout);
    }
  }, [isProcessing, errorMessage, currentStep]);

  const returnProcess = () => {
    setFileColumns([]);
    setFileData([]);
    setProspectsList([]);
    setWoodpeckerContacts([]);
    setCurrentStep(0);
    setIsReadyToProcess(true);
    setIsProcessing(false);
    setHasFinished(false);
    setErrorMessage('');
    setProcessLog([]);
    setProcessResults({
      total: 0,
      processed: 0,
      excluded: 0,
      newOrgs: 0,
      newPersons: 0,
      updatedPersons: 0,
      wpTotal: 0,
      duplicates: 0,
      lookupErrors: 0,
      rejectedContacts: 0,
      newProspects: 0,
      loadedContacts: 0
    });
    setConfigData({});
  };

  const resetProcess = () => {
    setIncludeReplies('no');
    setGooglesheetId('');
    setSheetName('');
    setApiKey('');
    setFileColumns([]);
    setFileData([]);
    setProspectsList([]);
    setWoodpeckerContacts([]);
    setCurrentStep(0);
    setIsReadyToProcess(false);
    setIsProcessing(false);
    setHasFinished(false);
    setErrorMessage('');
    setProcessLog([]);
    setProcessResults({
      total: 0,
      processed: 0,
      excluded: 0,
      newOrgs: 0,
      newPersons: 0,
      updatedPersons: 0,
      wpTotal: 0,
      duplicates: 0,
      lookupErrors: 0,
      rejectedContacts: 0,
      newProspects: 0,
      loadedContacts: 0
    });
    setConfigData({});
  };

  const updateProcessResults = ({ list = null, name, value }) => {
    if (list) {
      Object.entries(list).forEach(([key, listValue]) => setProcessResults(prev => ({
        ...prev,
        [key]: prev[key] + listValue
      })));
    } else {
      setProcessResults(prev => ({
        ...prev,
        [name]: prev[name] + value
      }));
    }
  };

  const updateProspectStatus = (prospects) => {
    if (Boolean(prospects.length)) {
      const prospectsMap = new Map(prospects.map(item => [item.email, item]));
      const updatedProspects = [...prospectsList].map(item => {
        const includedItem: any = prospectsMap.get(item.contact_email);
        const passesValidation = (includedItem?.hasExtraValidation && item['Deal Name'] === includedItem?.deal) || !includedItem?.hasExtraValidation;
        return includedItem && passesValidation ? { ...item, status: includedItem.status } : item;
      });
      setProspectsList(updatedProspects);
    }
  };

  const startStep = (stepName) => setProcessLog(prev => prev.concat({
    step: stepName,
    hasFinished: false,
    status: 'In execution'
  }));

  const completeStep = (stepName) => setProcessLog(prev => prev.map(item => {
    if (item.step === stepName) {
      item.hasFinished = true;
      item.status = 'Success';
    }

    return item;
  }));

  const markErrorOnStep = (stepName) => setProcessLog(prev => prev.map(item => {
    if (item.step === stepName) {
      item.hasFinished = true;
      item.status = 'Error';
    }

    return item;
  }));

  const executeStep = async () => {
    const step = steps[currentStep];

    try {
      startStep(step.name);
      // Execute the current step function
      await step.call();
      // Move to the next step
      completeStep(step.name);
      setCurrentStep(prev => prev + 1);
    } catch (error) {
      setErrorMessage(error.message || error);
      markErrorOnStep(step.name);
    }
  };

  const getGooglesheetData = async () => {
    try {
      // Make API request to Google Sheets using the obtained access token
      const fileRequest = await axios.get(`https://sheets.googleapis.com/v4/spreadsheets/${googlesheetId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        }
      });

      if (fileRequest && fileRequest.status === 200 && Boolean(fileRequest.data?.sheets?.length)) {
        setConfigData({ ...configData, file: fileRequest.data.properties });
        const dataRequest = await axios.get(`https://sheets.googleapis.com/v4/spreadsheets/${googlesheetId}/values/${sheetName}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          }
        });

        if (dataRequest && dataRequest.status === 200 && Boolean(dataRequest.data?.values?.length)) {
          const values = dataRequest.data.values.slice(1);
          setFileColumns(dataRequest.data.values[0]);
          setFileData(values);
          setProcessResults(prev => ({
            ...prev,
            total: values.length
          }));
        } else {
          throw new Error('No data available.');
        }
      } else {
        throw new Error('The source is not avaible for fetching.');
      }
    } catch (error) {
      throw new Error(`Loading data from the file. ${getErrorMessage(error)}`);
    }
  };

  const checkFileFormat = () => {
    const missingColumns = [];
    const requiredColumns = ['wp_campaign_id', 'Organization Name','contact_email'];
    const duplicates = fileColumns.filter((item, index) => fileColumns.indexOf(item) !== index);
    requiredColumns.forEach(item => {
      if (!fileColumns.includes(item)) {
        missingColumns.push(item);
      }
    });

    if (Boolean(missingColumns.length)) {
      throw new Error(`There are missing columns on the file: ${missingColumns}`);
    } else if (Boolean(duplicates.length)) {
      throw new Error(`There are duplicated columns on the file: ${duplicates}`);
    }
  };

  const addingMissingColumns = () => {
    const missingColumns = [];
    let updatedColumns = fileColumns;
    const pipeDriveColumns = { ...PSE_COLUMNS, ...OSE_COLUMNS, ...DSE_COLUMNS };
    const renameColumns = {
      'Organization City': 'Institution City',
      'Organization State': 'Institution State'
    };
    Object.keys(renameColumns).forEach(item => {
      const columnValue = renameColumns[item];
      const indexToReplace = fileColumns.indexOf(item);
      if (indexToReplace >= 0) {
        updatedColumns[indexToReplace] = columnValue;
      }        
    });
    Object.keys(pipeDriveColumns).forEach(item => {
      if (!updatedColumns.includes(item) && !missingColumns.includes(item)) {
        missingColumns.push(item);
      }
    });

    if (Boolean(missingColumns.length)) {
      updatedColumns = updatedColumns.concat(missingColumns);
    }

    setFileColumns(updatedColumns);
  };

  const createProspectObjects = async () => {
    const emailSet = new Set();
    const list = await Promise.all(fileData.map(item=> new Promise((res) => res(createProspectObject(item)))));
    list.forEach((item: any) => {
      if (emailSet.has(item.contact_email)) {
        throw new Error(`The email ${item.contact_email} is duplicated`);
      }
      emailSet.add(item.contact_email);
    });
    setProspectsList(list);
  };

  const createProspectObject = (data) => {
    const singleProspect = {};
    fileColumns.forEach((key, index) => {
      singleProspect[key] = data[index] || '';
    });
    return singleProspect;
  };

  const checkWoodpeckerCampaigns = async () => {
    try {
      const campaignIds = [...new Set(prospectsList.map(item => item.wp_campaign_id).filter(Boolean))];
      const data = await Promise.all(campaignIds.map(item=> new Promise((res) => res(campaignLookup(item)))));
      const campaignsData = campaignIds.reduce((acc, id, index) => {
        acc[id] = data[index];
        return acc;
      }, {});
      setCampaignsList(campaignsData)
    } catch (error) {
      throw new Error(error);
    }
  };

  const campaignLookup = async (campaignId) => {
    // Make API request to Woodpecker to get campaign data
    const request = await axios.get(`https://api-1.woodpecker.co/rest/v1/campaign_list?id=${campaignId}`, {
      headers: {
        'Authorization': WP_API_KEY
      }
    });

    if (request && request.status === 200 && Boolean(request.data?.length)) {
      const data = request.data[0];
      return { name: data.name, contacts: [] };
    } else {
      throw new Error(`The campaign ${campaignId} doesn't exist on Woodpecker`);
    }
  };

  const getPersonsData = async () => {
    try {
      const fields = await getPersonFields();
      const list = [];
      for (const item of prospectsList) {
        const result = await personLookup(item);
        list.push(result);
      }
      setPersonFields(fields);
      setProspectsList(list);
    } catch (error) {
      throw new Error(`Loading persons data from Pipedrive. ${getErrorMessage(error)}`);
    }
  };

  const getPersonFields = async () => {
    try {
      const dropdownFields = ['Blacklist', 'Rejection Reason', 'Contact Type', 'Expert Discipline', 'Expert Subject'];
      // Make API request to Pipedrive to get person fields
      const request = await axios.get('https://api.pipedrive.com/v1/personFields', {
        params: { api_token: apiKey }
      });

      if (request && request.status === 200 && Boolean(request.data?.data?.length)) {
        const allFields = request.data.data;
        const customFields = allFields.reduce((acc, curr) => {
          if (dropdownFields.includes(curr.name) && ['enum', 'set'].includes(curr.field_type)) {
            setLabelOptions(prev => ({
              ...prev,
              [curr.name]: transformToObject(curr.options, !['Blacklist', 'Rejection Reason'].includes(curr.name))
            }));
          }
          if (Object.values(PSE_COLUMNS).concat(['Organization','Name']).includes(curr.name)) {
            acc[curr.name] = curr.key;
          }
          return acc;
        }, {});
        return customFields;
      } else {
        throw new Error('No data available for person fields.');
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const personLookup = async (data) => {
    try {
      if (data.person_id) {
        return {
          ...data,
          person_group: 'update'
        }
      } else {
        const requestParams = {
          limit: 100,
          start: 0,
          item_types: 'person',
          term: data?.contact_email,
          api_token: apiKey
        };
        // Make API request to Pipedrive to get person data
        const request = await axios.get('https://api.pipedrive.com/v1/itemSearch?', {
          params: requestParams
        });

        if (request && request.status === 200 && Boolean(request.data?.data?.items)) {
          const requestData = request.data.data.items;
          const itemsCount = requestData.length;
          const personGroup = itemsCount === 1 ? 'update' : (itemsCount === 0 ? 'new' : 'mult');
          const prospectType = data['Prospect Type'];
          return {
            ...data,
            'Prospect Type': prospectType in CTE_COLUMNS ? CTE_COLUMNS[prospectType] : '',
            person_group: personGroup,
            person_id: personGroup === 'update' ? requestData[0].item.id : '',
            organization: personGroup === 'update' ? requestData[0].item.organization || null : null
          }
        } else {
          throw new Error(`No data available for ${data?.contact_email}.`);
        }
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const getOrgsData = async () => {
    try {
      const list = [];
      for (const item of prospectsList) {
        const result = await orgLookup(item);
        list.push(result);
      }
      setProspectsList(list);
    } catch (error) {
      throw new Error(`Loading organizations data from Pipedrive. ${getErrorMessage(error)}`);
    }
  };

  const orgLookup = async (data) => {
    try {
      if (data.org_id) {
        return {
          ...data,
          org_group: 'update'
        }
      } else {
        const requestParams = {
          limit: 100,
          start: 0,
          item_types: 'organization',
          term: data?.['Organization Name'],
          api_token: apiKey
        };
        // Make API request to Pipedrive to get organization data
        const request = await axios.get('https://api.pipedrive.com/v1/itemSearch?', {
          params: requestParams
        });

        if (request && request.status === 200 && Boolean(request.data?.data?.items)) {
          const requestData = request.data.data.items;
          const itemsCount = requestData.length;
          const orgGroup = itemsCount === 1 ? 'update' : (itemsCount === 0 ? 'new' : 'mult');
          const customOrg = data.organization?.id;
          return {
            ...data,
            org_group: customOrg ? 'update' : orgGroup,
            org_id: customOrg ? customOrg : (orgGroup === 'update' ? requestData[0].item.id : '')
          }
        } else {
          throw new Error(`No data available for ${data?.['Organization Name']}.`);
        }
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const getOwnerData = async () => {
    try {
      const uniqueOwners = prospectsList.reduce((acc, curr) => {
        const ownerName = curr.Owner?.trim();
        if (ownerName && !acc.includes(ownerName)) {
          acc.push(ownerName);
        }
        return acc;
      }, []);
      const ownerIds = [];
      for (const item of uniqueOwners) {
        const result = await ownerLookup(item);
        ownerIds.push(result);
      }
      const list = await Promise.all([...prospectsList].map(item=> new Promise((res) => res(consolidateProspectObject(item, ownerIds)))));
      setProspectsList(list);
    } catch (error) {
      throw new Error(`Loading owners data from Pipedrive. ${getErrorMessage(error)}`);
    }
  };

  const ownerLookup = async (data) => {
    try {
      const requestParams = {
        limit: 100,
        start: 0,
        search_by_email: 0,
        term: data,
        api_token: apiKey
      };
      // Make API request to Pipedrive to get owner data
      const request = await axios.get('https://api.pipedrive.com/v1/users/find?', {
        params: requestParams
      });

      if (request && request.status === 200 && Boolean(request.data?.data?.length)) {
        const requestData = request.data.data;
        const exactIndex = requestData.length > 1 ? requestData.findIndex(item => item.name === data) : 0;
        return {
          ownerName: data,
          ownerId: requestData[exactIndex >= 0 ? exactIndex : 0].id
        }
      } else {
        throw new Error(`No owner data available for ${data}.`);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const consolidateProspectObject = (data, owners) => {
    delete data.organization;
    const ownerId = data.Owner && owners.find(item => item.ownerName === data.Owner)?.ownerId;

    return {
      ...data,
      owner_id: ownerId || ''
    };
  };

  const processExclusions = async () => {
    try {
      const list = await Promise.all([...prospectsList].map(item=> new Promise((res) => res(applyExclusions(item)))));
      setProspectsList(list);
    } catch (error) {
      throw new Error(`Applying exclusions. ${getErrorMessage(error)}`);
    }
  };

  const applyExclusions = (data) => {
    let status = 'include';
    if (data.person_group === 'mult' && data.org_group === 'update') {
      status = 'consolidate_person';
    } else if (data.person_group === 'new' && data.org_group === 'mult') {
      status = 'select_org';
    } else if (data.person_group === 'mult' && data.org_group !== 'update') {
      status = 'select_person';
    }

    return {
      ...data,
      status: status,
      person_id: data.person_id.toString(),
      org_id: data.org_id.toString(),
      owner_id: data.owner_id.toString(),
    }
  };

  const createNewOrgs = async () => {
    try {
      const orgFields = await getOrgFields();
      const includedItems = prospectsList.filter((item: any) => item.status === 'include');
      const newOrgs = includedItems.reduce((acc, curr) => {
        if (curr.org_group === 'new' && !acc.find(item => item['Name'] === curr['Organization Name'])) {
          const singleOrg = {};

          Object.keys(OSE_COLUMNS).forEach(key => {
            const columnName = OSE_COLUMNS[key];
            singleOrg[columnName] = curr[key];
          });

          acc.push(singleOrg);
        }
        return acc;
      }, []);

      if (Boolean(newOrgs.length)) {
        const orgIds = [];
        for (const item of newOrgs) {
          const result = await createOrg(item, orgFields);
          orgIds.push(result);
        }
        const updatedItems = await Promise.all([...includedItems].map(item => new Promise((res) => res(updateProspectObject(
          item,
          orgIds,
          'Organization Name',
          'org_id'
        )))));
        const updatedProspects = combineIncludedToAllProspects(updatedItems, prospectsList);
        setProspectsList(updatedProspects);
        setProcessResults(prev => ({
          ...prev,
          newOrgs: newOrgs.length
        }));
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const getOrgFields = async () => {
    try {
      // Make API request to Pipedrive to get organization fields
      const request = await axios.get('https://api.pipedrive.com/v1/organizationFields', {
        params: { api_token: apiKey }
      });

      if (request && request.status === 200 && Boolean(request.data?.data?.length)) {
        const requestData = request.data.data;
        const orgFields = requestData.reduce((acc, curr) => {
          if (curr.key === 'label') {
            setLabelOptions(prev => ({
              ...prev,
              orgLabels: transformToObject(curr.options)
            }));
          }

          if (Object.values(OSE_COLUMNS).includes(curr.name)) {
            acc[curr.name] = curr.key;
          }
          return acc;
        }, {});
        return orgFields;
      } else {
        throw new Error('No data available for organization fields.');
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const createOrg = async (data, orgFields) => {
    try {
      const requestBody = Object.keys(orgFields).reduce((acc, curr) => {
        const columnName = orgFields[curr];
        const columnValue = data[curr];
        if (columnValue) {
          acc[columnName] = data[curr];
        }
        return acc;
      }, {});

      // Make API request to Pipedrive to create a new organization
      const request = await axios.post('https://api.pipedrive.com/v1/organizations', requestBody,  {
        params: { api_token: apiKey }
      });

      if (request && request.status === 201 && Boolean(request.data?.data)) {
        const requestData = request.data.data;
        return {
          name: requestData.name,
          id: requestData.id
        };
      } else {
        throw new Error(`The organization ${data?.['Name']} was not created.`);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const excludeOrgs = async () => {
    const includedItems = prospectsList.filter((item: any) => item.status === 'include');
    const orgs = includedItems.reduce((acc, curr) => {
      if (curr.org_group === 'update' && !acc.find(item => item.id === curr.org_id)) {
        acc.push({
          id: curr.org_id,
          name: curr['Organization Name']
        });
      }
      return acc;
    }, []);

    if (Boolean(orgs.length)) {
      const orgLabels = [];
      for (const item of orgs) {
        const result = await getOrgData(item);
        orgLabels.push(result);
      }
      const updatedItems = await Promise.all([...includedItems].map(item => new Promise((res) => res(updateProspectValues(
        item,
        { org_blacklist: "Won't Link/Blacklist", status: 'org removal' },
        (data) => orgLabels.find(item => item.id === data.org_id)?.label === "Won't Link/Blacklist"
      )))));
      const updatedProspects = combineIncludedToAllProspects(updatedItems, prospectsList);
      setProspectsList(updatedProspects);
    }
  };

  const getOrgData = async (data) => {
    try {
      // Make API request to Pipedrive to get organization data
      const request = await axios.get(`https://api.pipedrive.com/v1/organizations/${data.id}`, {
        params: { api_token: apiKey }
      });

      if (request && request.status === 200 && Boolean(request.data?.data)) {
        const orgLabel = request.data.data.label;
        const updatedOrg = { ...data, label: null };

        if (orgLabel) {
          const labelValue = labelOptions.orgLabels[orgLabel];
          updatedOrg.label = labelValue || null;
        }

        return updatedOrg;
      } else {
        throw new Error(`No data available for the organization ${data?.name}.`);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const createNewPersons = async () => {
    try {
      const includedItems = prospectsList.filter((item: any) => item.status === 'include');
      const newPersons = includedItems.reduce((acc, curr) => {
        if (curr.person_group === 'new' && curr.org_id) {
          const singlePerson = {
            Organization: curr.org_id,
            Name: `${curr.contact_name} ${curr['contact_family_name (last name)']}`.trim()
          };

          Object.keys(PSE_COLUMNS).forEach(key => {
            const columnName = PSE_COLUMNS[key];
            singlePerson[columnName] = curr[key];
          });

          singlePerson['Owner'] = curr.owner_id;

          acc.push(singlePerson);
        }
        return acc;
      }, []);
      
      if (Boolean(newPersons.length)) {
        const personIds = [];
        for (const item of newPersons) {
          const result = await createPerson(item);
          personIds.push(result);
        }
        const updatedItems = await Promise.all([...includedItems].map(item => new Promise((res) => res(updateProspectObject(
          item,
          personIds,
          'contact_email',
          'person_id'
        )))));
        const updatedProspects = combineIncludedToAllProspects(updatedItems, prospectsList);
        setProspectsList(updatedProspects);
        setProcessResults(prev => ({
          ...prev,
          newPersons: newPersons.length
        }));
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const createPerson = async (data) => {
    try {
      const fieldsToMatch = personFields;
      const requestBody = Object.keys(fieldsToMatch).reduce((acc, curr) => {
        const columnName = fieldsToMatch[curr];
        const isOptionField = ['Contact Type', 'Expert Discipline', 'Expert Subject'].includes(curr);
        const columnValue = isOptionField ? labelOptions[curr][data[curr]] : data[curr];
        if (columnValue) {
          acc[columnName] = isNaN(columnValue) ? columnValue : parseInt(columnValue, 10);
        }
        return acc;
      }, {});

      // Make API request to Pipedrive to create a new person
      const request = await axios.post('https://api.pipedrive.com/v1/persons', requestBody,  {
        params: { api_token: apiKey }
      });

      if (request && request.status === 201 && Boolean(request.data?.data)) {
        const requestData = request.data.data;
        return {
          name: requestData.primary_email,
          id: requestData.id
        };
      } else {
        throw new Error(`The person ${data?.['Name']} was not created.`);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const removeOpenDeals = async () => {
    const includedItems = prospectsList.filter((item: any) => item.status === 'include');
    const items = [];
    for (const item of includedItems) {
      const result = await getPersonData(item);
      items.push(result);
    }
    const updatedItems = await Promise.all(items.map(item=> new Promise((res) => res(updateProspectValues(
      item,
      { status: 'Open Deal' },
      () => item['open_deals_count'] > 1
    )))));
    const updatedProspects = combineIncludedToAllProspects(updatedItems, prospectsList);
    setProspectsList(updatedProspects);
  };

  const getPersonData = async (data) => {
    try {
      // Make API request to Pipedrive to get person data
      const request = await axios.get(`https://api.pipedrive.com/v1/persons/${data.person_id}`, {
        params: { api_token: apiKey }
      });

      if (request && request.status === 200 && Boolean(request.data?.data)) {
        const requestData = request.data.data;
        return {
          ...data,
          open_deals_count: requestData.open_deals_count,
          blacklist: requestData['01d23249ff556cc2bbb0041fc3db0cbfb8002aca'],
          rejection_reason: requestData['471861e33284a866ffab23a12c67ae89cc411bce'],
          last_activity_date: requestData.last_activity_date && dayjs().diff(dayjs(requestData.last_activity_date), 'days'),
          last_outgoing_mail_time: requestData.last_outgoing_mail_time && dayjs().diff(dayjs(requestData.last_outgoing_mail_time), 'days')
        };
      } else {
        throw new Error(`No data available for the person ${data?.name}.`);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const updatePersons = async () => {
    try {
      const includedItems = prospectsList.filter((item: any) => item.status === 'include');
      const itemsToUpdate = includedItems.reduce((acc, curr) => {
        if (curr.person_group === 'update' && curr.person_id) {
          const singlePerson = {
            person_id: curr.person_id,
            Organization: curr.org_id,
            Name: `${curr.contact_name} ${curr['contact_family_name (last name)']}`.trim()
          };

          Object.keys(PSE_COLUMNS).forEach(key => {
            const columnName = PSE_COLUMNS[key];
            singlePerson[columnName] = curr[key];
          });

          singlePerson['Owner'] = curr.owner_id;

          acc.push(singlePerson);
        }
        return acc;
      }, []);

      if (Boolean(itemsToUpdate.length)) {
        for (const item of itemsToUpdate) {
          await updatePersonData(item);
        }
        setProcessResults(prev => ({
          ...prev,
          updatedPersons: itemsToUpdate.length
        }));
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const updatePersonData = async (data) => {
    try {
      const fieldsToMatch = personFields;
      const requestBody = Object.keys(fieldsToMatch).reduce((acc, curr) => {
        const columnName = fieldsToMatch[curr];
        const isOptionField = ['Contact Type', 'Expert Discipline', 'Expert Subject'].includes(curr);
        const columnValue = isOptionField ? labelOptions[curr][data[curr]] : data[curr];
        if (columnValue) {
          acc[columnName] = isNaN(columnValue) ? columnValue : parseInt(columnValue, 10);
        }
        return acc;
      }, {});
      // Make API request to Pipedrive to update person data
      const request = await axios.put(`https://api.pipedrive.com/v1/persons/${data.person_id}`, requestBody, {
        params: { api_token: apiKey }
      });
      
      if (!request || request.status !== 200 || !Boolean(request.data?.data)) {
        throw new Error(`The person ${data?.Name} was not created.`);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const filterWoodpeckerList = async () => {
    const includedItems = prospectsList.filter((item: any) => item.status === 'include');
    const updatedItems = await Promise.all([...includedItems].map(item=> new Promise((res) => res(applyWoodpeckerFilters(item)))));
    const updatedProspects = await combineIncludedToAllProspects(updatedItems, prospectsList);
    setProspectsList(updatedProspects);
  };

  const applyWoodpeckerFilters = (data) => {
    const blacklistValue = data.blacklist && labelOptions.Blacklist[data.blacklist];
    const rejectionValue = data.rejection_reason && labelOptions['Rejection Reason'][data.rejection_reason];
    
    if (blacklistValue === 'Blacklisted') {
      data.blacklist = blacklistValue;
      data.status = 'Blacklisted';
    }
    
    if (['Not Interested', 'Incorrect Contact'].includes(rejectionValue)) {
      data.rejection_reason = rejectionValue;
      data.status = 'Prior Rejector';
    }

    if (data.last_activity_date && data.last_activity_date <= 60) {
      data.status = 'Recent Email';
    }

    if (data.last_activity_date && data.last_activity_date <= 45) {
      data.status = 'Recent Activity';
    }
    
    return data;
  };

  const executeWoodpeckerProcess = async () => {
    try {
      let totalToLoad = 0;
      const campaignData = { ...campaignsList };
      prospectsList.forEach(item => {
        const campaignId = item.wp_campaign_id;
        if (item.status === 'include' && campaignId && item['Deal Name']) {
          campaignData[campaignId].contacts.push(item);
          ++totalToLoad;
        }
      });
      setProcessResults(prev => ({
        ...prev,
        wpTotal: totalToLoad
      }));
      const woodPeckerResults = await loadWoodpeckerContacts(campaignData, includeReplies, updateProspectStatus, updateProcessResults);
      setWoodpeckerContacts(woodPeckerResults);
    } catch (error) {
      throw new Error(error);
    }
  };

  const createDeals = async () => {
    const dealFields = await getDealFields();
    for (const prospects of woodpeckerContacts) {
      for (const item of prospects) {
        await createPipedriveDeal(item, dealFields);
      }
    }
  };

  const getDealFields = async () => {
    try {
      // Make API request to Pipedrive to get organization fields
      const request = await axios.get('https://api.pipedrive.com/v1/dealFields', {
        params: { api_token: apiKey }
      });

      if (request && request.status === 200 && Boolean(request.data?.data?.length)) {
        const requestData = request.data.data;
        const columnsToSearch = Object.values(DSE_COLUMNS).filter(item => !['Title', 'Owner'].includes(item));
        const dealFields = requestData.reduce((acc, curr) => {
          if (columnsToSearch.includes(curr.name)) {
            acc[curr.name] = curr.key;
          }
          return acc;
        }, {});
        return dealFields;
      } else {
        throw new Error('No data available for deal fields.');
      }
    } catch (error) {
      throw error;
    }
  };

  const createPipedriveDeal = async (prospect, dealFields) => {
    const pipedriveData = prospectsList.find(item => item.contact_email === prospect.email);
    
    try {  
      const requestBody = Object.keys(dealFields).reduce((acc, curr) => {
        const columnName = dealFields[curr];
        const columnValue = pipedriveData[curr];
        if (columnValue) {
          acc[columnName] = pipedriveData[curr];
        }
        return acc;
      }, { title: pipedriveData['Deal Name'], user_id: pipedriveData.owner_id, org_id: pipedriveData.org_id, person_id: pipedriveData.person_id });

      // Make API request to Pipedrive to create a new organization
      await axios.post('https://api.pipedrive.com/v1/deals', requestBody, {
        params: { api_token: apiKey }
      });
    } catch (error) {
      throw new Error(`The deal ${pipedriveData['Deal Name']} was not created.`);
    }
  };

  const generateSpreadsheetTabs = async () => {
    try {
      const excludedItems = prospectsList.filter(item => item.status !== 'include');
      const processedItems = prospectsList.filter(item => item.status === 'include');
      setProcessResults(prev => ({
        ...prev,
        processed: processedItems.length,
        excluded: excludedItems.length
      }));

      const columns = Object.keys(prospectsList[0]);
      const excludedRows = excludedItems.map(item => Object.values(item));
      const includedRows = processedItems.map(item => Object.values(item));
      const createSheetRequest = await axios.post(
        `https://sheets.googleapis.com/v4/spreadsheets/${googlesheetId}:batchUpdate`, {
          requests: [
            {
              addSheet: {
                properties: {
                  title: 'Compare to PD'
                }
              }
            },
            {
              addSheet: {
                properties: {
                  title: 'Excluded items'
                }
              }
            }
          ]
        }, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        }
      );
  
      if (createSheetRequest?.status === 200) {
        const includedDataRequest = await axios.post(
          `https://sheets.googleapis.com/v4/spreadsheets/${googlesheetId}/values/Compare to PD!A%3AA:append?valueInputOption=USER_ENTERED`,
          {
            majorDimension: 'ROWS',
            values: [columns, ...includedRows]
          }, {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          }
        );
  
        if (includedDataRequest?.status === 200) {
          const excludedDataRequest = await axios.post(
            `https://sheets.googleapis.com/v4/spreadsheets/${googlesheetId}/values/Excluded items!A%3AA:append?valueInputOption=USER_ENTERED`,
            {
              majorDimension: 'ROWS',
              values: [columns, ...excludedRows]
            }, {
              headers: {
                'Authorization': `Bearer ${accessToken}`
              }
            }
          );
    
          if (excludedDataRequest?.status !== 200) {
            throw new Error('The excluded items were not added.');
          }
        } else {
          throw new Error('The included items were not added.');
        }
      } else {
        throw new Error('The new tab was not created.');
      }
    } catch (error) {
      throw new Error(`Adding data to the file. ${getErrorMessage(error)}`);
    }
  };

  const updateProspectValues = (data, attributes, validationFunction) => {
    if (!validationFunction(data)) {
      return data;
    }

    Object.keys(attributes).forEach(item => {
      data[item] = attributes[item];
    });

    return data;
  };

  const updateProspectObject = (data, values, fieldToSearch, fieldToUpdate) => {
    const fieldValue = values.find(item => item.name === data[fieldToSearch])?.id;

    return {
      ...data,
      [fieldToUpdate]: fieldValue?.toString() || data[fieldToUpdate]
    };
  };

  const transformToObject = (array, keyIsLabel = false) => array.reduce((acc, obj) => {
    if (keyIsLabel) {
      acc[obj.label] = obj.id;
    } else {
      acc[obj.id] = obj.label;
    }
    return acc;
  }, {});

  const combineIncludedToAllProspects = (includedItems, prospectItems) => {
    const includedListMap = new Map(includedItems.map(item => [item.contact_email, item]));

    const mergedList = prospectItems.map(prospect => {
      const includedItem: any = includedListMap.get(prospect.contact_email);
      const hasSameContactName = prospect.contact_name === includedItem?.contact_name;
      const hasSameContactLastName = prospect['contact_family_name (last name)'] === includedItem?.['contact_family_name (last name)'];
      return includedItem && hasSameContactName && hasSameContactLastName ? { ...prospect, ...includedItem } : prospect;
    });

    return mergedList;
  };

  const steps = [
    { name: 'Fetching data from the file', call: () => getGooglesheetData() },
    { name: 'Validating file format', call: () => checkFileFormat() },
    { name: 'Adding missing columns', call: () => addingMissingColumns() },
    { name: 'Create prospect objects', call: () => createProspectObjects() },
    { name: 'Validating available Woodpecker campaigns', call: () => checkWoodpeckerCampaigns() },
    { name: 'Merge persons data', call: () => getPersonsData() },
    { name: 'Merge organizations data', call: () => getOrgsData() },
    { name: 'Merge owner data', call: () => getOwnerData() },
    { name: 'Process exclusions', call: () => processExclusions() },
    { name: 'Create new organizations', call: () => createNewOrgs() },
    { name: 'Exclude no-go organizations', call: () => excludeOrgs() },
    { name: 'Create new persons', call: () => createNewPersons() },
    { name: 'Remove open deals', call: () => removeOpenDeals() },
    { name: 'Update existings persons', call: () => updatePersons() },
    { name: 'Cutting down Woodpecker list', call: () => filterWoodpeckerList() },
    { name: 'Executing Woodpecker process', call: () => executeWoodpeckerProcess() },
    { name: 'Create deals', call: () => createDeals() },
    { name: 'Updating spreadsheet tabs', call: () => generateSpreadsheetTabs() }
  ];

  return (
    <Flex flexDirection="column" alignItems="center" h="full">
      <Heading textAlign="center" mb={10}>Pipedrive to Woodpecker Loader</Heading>
      <Text mb={8}>This process lets you upload files to PipeDrive and Woodpecker, assuming you have already processed these files with MoMailer.</Text>
      <Box w="full" borderWidth="1px" rounded="lg" shadow="1px 1px 3px rgba(0,0,0,0.3)" p={8}>
        {!isProcessing && !hasFinished && (
          <Flex background="#F6F8FF" borderRadius="8px" justifyContent="center" py={8}>
            <FormControl w="600px">
              <FormLabel htmlFor="google-sheet" mb={1}>
                Google Sheet ID
              </FormLabel>
              <Input
                id="google-sheet"
                value={googlesheetId}
                placeholder="Paste Google Sheet ID here"
                background="white"
                onChange={(e) => setGooglesheetId(e.target.value)}
                mb={4} />
              <FormLabel htmlFor="sheet-name" mb={1}>
                Tab Name
              </FormLabel>
              <Input
                id="sheet-name"
                value={sheetName}
                background="white"
                onChange={(e) => setSheetName(e.target.value)}
                mb={4} />
              <FormLabel htmlFor="api-key" mb={1}>
                API Key
              </FormLabel>
              <Input
                id="api-key"
                value={apiKey}
                background="white"
                onChange={(e) => setApiKey(e.target.value)}
                mb={4} />
              <FormLabel htmlFor="include-replies" mb={1}>
                Include REPLIED
              </FormLabel>
              <RadioGroup id="include-replies" mb={4} value={includeReplies} onChange={(e) => setIncludeReplies(e)}>
                <Flex gap={5}>
                  <Radio value="yes">
                    Yes, include
                  </Radio>
                  <Radio value="no">
                    No, exclude
                  </Radio>
                </Flex>
              </RadioGroup>
              <Flex justifyContent="center" mt={8}>
                <Button
                  w="150px"
                  variant="solid"
                  colorScheme="blue"
                  isDisabled={!isReadyToProcess}
                  isLoading={isProcessing}
                  onClick={() => setIsProcessing(true)}>
                  Process
                  <Icon boxSize={8} ml={1} as={WiStars} />
                </Button>
              </Flex>
            </FormControl>
          </Flex>
        )}
        {isProcessing && !hasFinished && Boolean(processLog.length) && (<>
          <Heading as="h3" size="md" textAlign="center" mb={6}>Progress</Heading>
          <div>
            {processLog.map((item, index) => (
              <Flex key={`progress-list-${index}`} px="15%" alignItems="center" w="100%" gap="5%" mb={index < processLog.length - 1 ? 6 : 0}>
                <Text w="30%">{`Step ${index + 1}: ${item.step}`}</Text>
                <Progress
                  w="45%"
                  borderRadius="5px"
                  isIndeterminate={!item.hasFinished}
                  value={item.hasFinished ? 100 : 0} />
                <Badge
                  colorScheme={PROCESS_STATUS_LIST[item.status]}
                  borderRadius="5px"
                  textAlign="center"
                  py={1}
                  px={2}
                  w="15%">
                  {item.status}
                </Badge>
              </Flex>
            ))}
          </div>
          {errorMessage && (
            <Box w="auto" mt={8} mx="15%">
              <Alert status="error">
                <AlertIcon />
                <AlertTitle>Error:</AlertTitle>
                <AlertDescription>
                  {errorMessage}
                </AlertDescription>
              </Alert>
              <Flex mt={8} justifyContent="center" gap={4}>
                <Button
                  w="150px"
                  variant="outline"
                  colorScheme="blue"
                  onClick={() => returnProcess()}>
                  <Icon boxSize={4} mr={1} as={FiArrowLeft} />
                  Back
                </Button>
                <Button
                  w="150px"
                  variant="outline"
                  colorScheme="blue"
                  onClick={() => resetProcess()}>
                  <Icon boxSize={4} mr={2} as={FiRotateCw} />
                  Start again
                </Button>
              </Flex>
            </Box>
          )}
        </>)}     
        {!isProcessing && hasFinished && (
          <Flex flexDirection="column" alignItems="center">
            <Heading as="h3" size="lg" color="#57C600" mb={6}>Success</Heading>
            <Text mb={2}>The data has been successfully added into Pipedrive and Woodpecker.</Text>
            <Link href={`https://docs.google.com/spreadsheets/d/${googlesheetId}`} fontWeight="bold" color="blue.600" isExternal mb={2}>
              {`Spreadsheet: ${configData.file.title}`}
              <Icon ml={1} as={FiExternalLink} />
            </Link>
            <Flex w="full" mt={3} mb={8} justifyContent="center" alignItems="center" gap={4}>
              <Heading as="h5" size="sm" mb={6}>Pipedrive results:</Heading>
              <Box>
                <Flex gap={4}>
                  <Badge p="8px 12px">{`Initial contacts: ${processResults.total}`}</Badge>
                  <Badge colorScheme="blue" p="8px 12px">{`New organizations: ${processResults.newOrgs}`}</Badge>
                  <Badge colorScheme="blue" p="8px 12px">{`New persons: ${processResults.newPersons}`}</Badge>   
                </Flex>
                <Flex mt={3} gap={4}>
                  <Badge colorScheme="green" p="8px 12px">{`Processed persons: ${processResults.processed}`}</Badge>
                  <Badge colorScheme="red" p="8px 12px">{`Excluded items: ${processResults.excluded}`}</Badge>
                  <Badge colorScheme="green" p="8px 12px">{`Updated persons: ${processResults.updatedPersons}`}</Badge>
                </Flex>
              </Box>
            </Flex>
            <Flex w="full" mt={3} mb={8} justifyContent="center" alignItems="center" gap={4}>
              <Heading as="h5" size="sm" mb={6}>Woodpecker results:</Heading>
              <Box>
                <Flex gap={4}>
                  <Badge p="8px 12px">{`Initial contacts: ${processResults.wpTotal}`}</Badge>
                  <Badge colorScheme="red" p="8px 12px">
                    {`Excluded contacts: ${processResults.duplicates + processResults.lookupErrors + processResults.rejectedContacts}`}
                  </Badge>
                </Flex>
                <Flex mt={3} gap={4}>
                  <Badge colorScheme="blue" p="8px 12px">{`New contacts added: ${processResults.newProspects}`}</Badge>
                  <Badge colorScheme="green" p="8px 12px">{`Updated contacts: ${processResults.loadedContacts}`}</Badge>
                </Flex>
              </Box>
            </Flex>
            <Button
              w="150px"
              variant="outline"
              colorScheme="blue"
              onClick={() => resetProcess()}>
              <Icon boxSize={4} mr={2} as={FiRotateCw} />
              Start again
            </Button>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};
