'use client'

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Heading,
  Flex,
  Box,
  SimpleGrid,
  Divider,
  Text,
  UnorderedList,
  ListItem,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription
} from '@chakra-ui/react';
import { useGeneralContext } from '../../App';
import { ProgressBar } from '../../components/ProgressBar';
import { ComponentBox } from '../../components/ComponentBox';
import { Confirmation } from '../../components/Confirmation';
import { COLLECTIONS_LIST } from '../../utils/constants';
import {
  replaceMultipleStrings,
  getReplacements,
  getUpdatedDbData,
  getUpdatedWinnerCompany,
  findMatchingObject,
  formatDate
} from '../../utils/utils';

export const DynamicComponents = () => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [componentsToModify, setComponentsToModify] = useState<any>({
    headline: false,
    content: false,
    steps: false,
    ranking: false,
    db: false,
    listicle: false,
    productCard: false,
    companyHighlight: false,
    companyHighlightQuery: false
  });

  const {
    config,
    collection,
    operationType,
    pageData,
    stateToProcess,
    cityToProcess,
    resultPages,
    setResultPages,
    monthToReplace,
    currentDateFilter,
    newMonth,
    newDateFilter,
    modifyPageDate
  } = useGeneralContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!pageData || !stateToProcess || !resultPages.length) {
      navigate('/');
    }
  }, [pageData, stateToProcess, resultPages]);

  const handleProcess = async () => {
    setIsProcessing(true);
    setErrorMessage('');
    try {
      const updatedFlexZones = await Promise.all(resultPages.map(page => new Promise((res) => res(getUpdatedPage(page)))));
      await Promise.all(updatedFlexZones.map(page => new Promise((res) => res(updateCopyPage(page.flex_zone, page.id)))));
      setResultPages(updatedFlexZones);
      navigate('/created');
    } catch(e) {
      setErrorMessage(e.response && e.response.headers || e.request || e.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const onCheckboxChange = (name, value) => {
    setComponentsToModify({
      ...componentsToModify,
      [name]: value
    });
  };

  const onCheckAllChange = (name, value) => {
    setComponentsToModify({
      headline: value,
      content: value,
      steps: value,
      ranking: value,
      db: value,
      listicle: value,
      productCard: value,
      companyHighlight: value,
      companyHighlightQuery: value
    });
  };

  const getUpdatedPage = async (page) => {
    const newFlexZone = await Promise.all(page.flex_zone.map(component =>
      new Promise((res) => res(getUpdatedComponent(component, page.replace)))
    ));

    return { ...page, flex_zone: newFlexZone };
  };

  const getUpdatedComponent = async (component, replace) => {
    const updatedComponent = { ...component };
    const customReplacements = modifyPageDate ? {
      [monthToReplace]: newMonth,
      [formatDate(currentDateFilter)]: formatDate(newDateFilter)
    } : {};
    const replacements = getReplacements(stateToProcess, cityToProcess, replace, operationType, customReplacements);
    delete updatedComponent.id;

    if (component['__component'] === 'page.headline' && componentsToModify.headline) {
      updatedComponent.text = replaceMultipleStrings(component.text, replacements);
      updatedComponent.subnav_text = replaceMultipleStrings(component.subnav_text, replacements, 35);
      updatedComponent.category_winner_text = replaceMultipleStrings(component.category_winner_text, replacements);
    } else if (component['__component'] === 'page.content' && componentsToModify.content) {
      updatedComponent.copy = replaceMultipleStrings(component.copy, replacements);
    } else if (component['__component'] === 'page.step-component' && componentsToModify.steps) {
      updatedComponent.copy = replaceMultipleStrings(component.copy, replacements);
      updatedComponent.headline = component.headline ? {
        ...component.headline,
        text: replaceMultipleStrings(component.headline.text, replacements),
        subnav_text: replaceMultipleStrings(component.headline.subnav_text, replacements, 35),
        category_winner_text: replaceMultipleStrings(component.headline.category_winner_text, replacements)
      } : null;
      updatedComponent.step = component.step.map(step => ({
        title: replaceMultipleStrings(step.title, replacements),
        description: replaceMultipleStrings(step.description, replacements)
      }));
    } else if (component['__component'] === 'page.summary-ranking' && componentsToModify.ranking) {
      updatedComponent.headline = replaceMultipleStrings(component.headline, replacements, 70);
      updatedComponent.badge_title = replaceMultipleStrings(component.badge_title, replacements, 15);
      updatedComponent.badge_subtitle_1 = replaceMultipleStrings(component.badge_subtitle_1, replacements, 20);
      updatedComponent.badge_subtitle_2 = replaceMultipleStrings(component.badge_subtitle_2, replacements, 15);
    } else if (component['__component'] === 'database.db-query-table' && componentsToModify.db) {
      const jsonData = JSON.parse(component.playground);
      const newQuery = replaceMultipleStrings(jsonData.query, replacements);
      const newDataset = await getUpdatedDbData(newQuery, config.current);
      updatedComponent.playground = JSON.stringify({ ...jsonData, query: newQuery, dataset: newDataset });
      updatedComponent.winner_text = replaceMultipleStrings(component.winner_text, replacements);
      updatedComponent.winner_company = await getUpdatedWinnerCompany({
        data: newDataset,
        mainColumn: jsonData.sortColumn,
        referenceColumn: findMatchingObject(jsonData.columnsConfig, item => ['Company', 'company'].includes(item.display_text)),
        filters: jsonData.defaults,
        config: config.current
      });
      
      if (component.title) {
        const { text, subnav_text, category_winner_text } = component.title;
        updatedComponent.title = {
          ...component.title,
          text: replaceMultipleStrings(text, replacements),
          subnav_text: replaceMultipleStrings(subnav_text, replacements),
          category_winner_text: replaceMultipleStrings(category_winner_text, replacements)
        };
      }
    } else if (component['__component'] === 'database.query-product-card' && componentsToModify.productCard) {
      const jsonData = JSON.parse(component.playground);
      const newQuery = replaceMultipleStrings(jsonData.query, replacements);
      const newDataset = await getUpdatedDbData(newQuery, config.current);
      updatedComponent.category_winner_text = replaceMultipleStrings(component.category_winner_text, replacements);
      updatedComponent.overview = replaceMultipleStrings(component.overview, replacements);
      updatedComponent.pros = replaceMultipleStrings(component.pros, replacements);
      updatedComponent.cons = replaceMultipleStrings(component.cons, replacements);
      updatedComponent.score = replaceMultipleStrings(component.score, replacements);
      updatedComponent.playground = JSON.stringify({ ...jsonData, query: newQuery, dataset: newDataset });
      updatedComponent.company = await getUpdatedWinnerCompany({
        data: newDataset,
        mainColumn: jsonData.score,
        referenceColumn: jsonData.company,
        config: config.current
      });

      if (component.title) {
        const { text, subnav_text, category_winner_text } = component.title;
        updatedComponent.title = {
          ...component.title,
          text: replaceMultipleStrings(text, replacements),
          subnav_text: replaceMultipleStrings(subnav_text, replacements),
          category_winner_text: replaceMultipleStrings(category_winner_text, replacements)
        };
      }
      if (component.product_label) {
        const { title, subtitle } = component.product_label;
        updatedComponent.product_label = {
          ...component.product_label,
          title: replaceMultipleStrings(title, replacements),
          subtitle: replaceMultipleStrings(subtitle, replacements)
        };
      }
      if (component.offer_title) {
        const { title } = component.offer_title;
        updatedComponent.offer_title = {
          ...component.offer_title,
          title: replaceMultipleStrings(title, replacements)
        };
      }
    } else if (component['__component'] === 'database.db-query-listicle' && componentsToModify.listicle) {
      const jsonData = JSON.parse(component.playground);
      //Query only changes if there's an state or city to filter
      if (/state|city/i.test(jsonData.query)) {
        const newQuery = replaceMultipleStrings(jsonData.query, replacements);
        const newDataset = await getUpdatedDbData(newQuery, config.current);
        const newItemsList = newDataset.map(() => ({
          'title': null,
          'size': 'h6',
          'category_winner_text': null,
          'subnav_text': null,
          'compliance': null,
          'pros': null,
          'cons': null,
          'why_we_geek_out': null,
          'sub_header': null,
          'mobile_default': 'Expanded',
          'desktop_default': 'Expanded'
        }));
        updatedComponent.playground = JSON.stringify({ ...jsonData, query: newQuery, dataset: newDataset, listicleItems: newItemsList });
      }
    } else if (component['__component'] === 'company.company-highlight' && componentsToModify.companyHighlight) {
      updatedComponent.Name = replaceMultipleStrings(component.Name, replacements);
      updatedComponent.description = replaceMultipleStrings(component.description, replacements);
      updatedComponent.category_winner_text = replaceMultipleStrings(component.category_winner_text, replacements, 45);
    } else if (component['__component'] === 'database.company-highlight-query' && componentsToModify.companyHighlightQuery) {
      const jsonData = JSON.parse(component.query);
      const newQuery = replaceMultipleStrings(jsonData.query, replacements);
      const newDataset = await getUpdatedDbData(newQuery, config.current);
      updatedComponent.query = JSON.stringify({ ...jsonData, query: newQuery, dataset: newDataset });
      updatedComponent.title = replaceMultipleStrings(component.title, replacements);
      updatedComponent.description = replaceMultipleStrings(component.description, replacements);
    }

    return updatedComponent;
  };

  const updateCopyPage = async (newFlexZone, pageID) => {
    await axios.put(`${config.current.strapiUrl}/api/${COLLECTIONS_LIST[collection].api}/${pageID}`, { data: { flex_zone: newFlexZone } }, {
      headers: {
        Authorization: `Bearer ${config.current.token}`
      }
    });
  };

  return (
    <Flex flexDirection="column" alignItems="center" h="full">
      <ProgressBar processName="pageDuplication" currentStep={1} />
      <Heading textAlign="center" mb={10}> Select Dynamic Components</Heading>
      <Box w="full" borderWidth="1px" rounded="lg" shadow="1px 1px 3px rgba(0,0,0,0.3)" py={6} px={44}>
        {errorMessage && (
          <Alert status="error" mb={6}>
            <AlertIcon />
            <AlertTitle>Strapi error:</AlertTitle>
            <AlertDescription>
              {errorMessage}
            </AlertDescription>
          </Alert>
        )}
        {pageData && (
          <SimpleGrid gridTemplateColumns="repeat(3, 1fr)" gridGap={5}>
            <ComponentBox name="all" icon="select" onCheckboxChange={onCheckAllChange} />
            {pageData.flex_zone.some(component => component['__component'] === 'page.headline') && <ComponentBox
              name="headline"
              isSelectedByParent={componentsToModify.headline}
              onCheckboxChange={onCheckboxChange}
            />}
            {pageData.flex_zone.some(component => component['__component'] === 'page.content') && <ComponentBox
              name="content"
              isSelectedByParent={componentsToModify.content}
              onCheckboxChange={onCheckboxChange}
            />}
            {pageData.flex_zone.some(component => component['__component'] === 'page.step-component') && <ComponentBox
              name="steps"
              isSelectedByParent={componentsToModify.steps}
              onCheckboxChange={onCheckboxChange}
            />}
            {pageData.flex_zone.some(component => component['__component'] === 'page.summary-ranking') && <ComponentBox
              name="ranking"
              isSelectedByParent={componentsToModify.ranking}
              onCheckboxChange={onCheckboxChange}
            />}
            {pageData.flex_zone.some(component => component['__component'] === 'company.company-highlight') && <ComponentBox
              name="companyHighlight"
              isSelectedByParent={componentsToModify.companyHighlight}
              onCheckboxChange={onCheckboxChange}
            />}
            {pageData.flex_zone.some(component => component['__component'] === 'database.db-query-table') && <ComponentBox
              name="db"
              isSelectedByParent={componentsToModify.db}
              onCheckboxChange={onCheckboxChange}
            />}
            {pageData.flex_zone.some(component => component['__component'] === 'database.db-query-listicle') && <ComponentBox
              name="listicle"
              isSelectedByParent={componentsToModify.listicle}
              onCheckboxChange={onCheckboxChange}
            />}
            {pageData.flex_zone.some(component => component['__component'] === 'database.query-product-card') && <ComponentBox
              name="productCard"
              isSelectedByParent={componentsToModify.productCard}
              onCheckboxChange={onCheckboxChange}
            />}
            {pageData.flex_zone.some(component => component['__component'] === 'database.company-highlight-query') && <ComponentBox
              name="companyHighlightQuery"
              icon="companyHighlight"
              isSelectedByParent={componentsToModify.companyHighlightQuery}
              onCheckboxChange={onCheckboxChange}
            />}
          </SimpleGrid>
        )}
        <Divider my={6} />
        <Text fontWeight="bold" mb={4}>
          {`Data found on the ${operationType === 'city' ? cityToProcess + ', ' : ''}${stateToProcess} template page`}
        </Text>
        {pageData && (
          <Box>
            <b>Headline: </b>
            <UnorderedList>
              {pageData.flex_zone.filter(component => component['__component'] === 'page.headline').map((item, index) => (
                <ListItem key={`headline-${index}`}>
                  {item.text}
                </ListItem>
              ))}
            </UnorderedList>
          </Box>
        )}
      </Box>
      <Confirmation
        buttonLabel="Update Pages"
        bodyDescription={`Please check template data and selected components to replace before moving on.\n
        Headline: ${componentsToModify.headline ? 'Yes' : 'No'}.\n
        Content: ${componentsToModify.content ? 'Yes' : 'No'}.\n
        Steps: ${componentsToModify.steps ? 'Yes' : 'No'}.\n
        Summary Ranking: ${componentsToModify.ranking ? 'Yes' : 'No'}.\n
        DB Query Table: ${componentsToModify.db ? 'Yes' : 'No'}.\n
        DB Query Listicle: ${componentsToModify.listicle ? 'Yes' : 'No'}.\n
        Query Product Card: ${componentsToModify.productCard ? 'Yes' : 'No'}.\n
        Company Highlight: ${componentsToModify.companyHighlight ? 'Yes' : 'No'}.\n
        Company Highlight Query: ${componentsToModify.companyHighlightQuery ? 'Yes' : 'No'}.`}
        onClickEvent={() => handleProcess()}
        shouldBeLoading={isProcessing}
        shouldBeDisabled={false} />
    </Flex>
  );
};
