'use client'

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import dayjs from 'dayjs';
import {
  Heading,
  Flex,
  Box,
  SimpleGrid,
  Divider,
  Text,
  UnorderedList,
  ListItem,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription
} from '@chakra-ui/react';
import { useGeneralContext } from '../../App';
import { ProgressBar } from '../../components/ProgressBar';
import { ComponentBox } from '../../components/ComponentBox';
import { Confirmation } from '../../components/Confirmation';
import { COLLECTIONS_LIST } from '../../utils/constants';
import { replaceMultipleStrings, cleanRelationFields, formatToPermalink, combineCreditCardImages } from '../../utils/utils';

export const StaticCCComponents = () => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [componentsToModify, setComponentsToModify] = useState<any>({
    permalink: true,
    name: false,
    breadcrumb: false,
    banner: false,
    meta: false
  });

  const {
    config,
    pageData,
    setResultPages,
    itemsToReplicate,
  } = useGeneralContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!pageData || !itemsToReplicate.length) {
      navigate('/');
    }

    setResultPages([]);
  }, [pageData, itemsToReplicate]);

  const handleProcess = async () => {
    setIsProcessing(true);
    setErrorMessage('');
    try {
      const updatedValues = await Promise.all(itemsToReplicate.map(item=> new Promise((res) => res(getUpdatedCopyData(item)))));
      await Promise.all(updatedValues.map((page, index) => new Promise((res) => res(createCopyPage(page, index)))));
      navigate('/components/dynamic/cc');
    } catch(e) {
      setErrorMessage(e.response && e.response.headers || e.request || e.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const onCheckboxChange = (name, value) => {
    setComponentsToModify({
      ...componentsToModify,
      [name]: value
    });
  };

  const onCheckAllChange = (name, value) => {
    setComponentsToModify({
      permalink: true,
      name: value,
      breadcrumb: value,
      banner: value,
      meta: value
    });
  };

  const uploadImageToStrapi = async (ccId1, ccId2, image) => {
    const file = new File([image], 'image.png', { type: image.type });
    const formData = new FormData();
    formData.append('files', file);

    const currentDate = dayjs().format('YYYY-MM-DD');
    const customImageName = `CCVersusImage_${ccId1}_${ccId2}_${currentDate}`;
    formData.append('fileInfo', JSON.stringify({ name: customImageName }));

    const uploadResponse = await axios.post(`${config.current.strapiUrl}/api/upload`, formData, {
      headers: {
        Authorization: `Bearer ${config.current.token}`,
        'Content-Type': 'multipart/form-data'
      }
    });

    if (uploadResponse && uploadResponse.status === 200 && Boolean(uploadResponse.data?.length)) {
      return uploadResponse.data[0].id;
    }
    
    return null;
  };
  
  const getUpdatedCopyData = async (item) => {
    let combinedImageId;
    const { card_1, card_2 } = item;
    const replacements = {
      [card_1.originalFullName]: card_1.newName,
      [card_2.originalFullName]: card_2.newName,
      ...card_1.originalShortName && {
        [card_1.originalShortName]: card_1.newName
      },
      ...card_2.originalShortName && {
        [card_2.originalShortName]: card_2.newName
      }
    };

    if (componentsToModify.meta) {
      const combinedImageBlob = await combineCreditCardImages(card_1.image, card_1.newName, card_2.image, card_2.newName);

      if (combinedImageBlob) {
        combinedImageId = await uploadImageToStrapi(card_1.newValue, card_2.newValue, combinedImageBlob);
      }
    }

    const updatedPermalink = `/credit-cards/compare/${formatToPermalink(card_1.newName)}-vs-${formatToPermalink(card_2.newName)}`;
    const updatedBreadcrumb = replaceMultipleStrings(pageData.publishing?.breadcrumb, replacements);
    const updatedBannerTitle = replaceMultipleStrings(pageData.banner?.title, replacements);
    const updatedMetaTitle = replaceMultipleStrings(pageData.meta?.title, replacements);
    const updatedMetaDescription = replaceMultipleStrings(pageData.meta?.description, replacements);

    delete pageData.publishing?.id;
    delete pageData.banner?.id;
    delete pageData.meta?.id;

    return {
      ...pageData,
      publishedAt: null,
      name: replaceMultipleStrings(pageData.name, replacements),
      authors: cleanRelationFields(pageData.authors),
      experts: cleanRelationFields(pageData.experts),
      reviewed_by: cleanRelationFields(pageData.reviewed_by),
      verified_by: cleanRelationFields(pageData.verified_by),
      kevel: cleanRelationFields(pageData.kevel),
      editor: cleanRelationFields(pageData.editor),
      flex_zone: cleanRelationFields([...pageData.flex_zone]),
      syndication: cleanRelationFields(pageData.syndication),
      versus_cards: { card_1: card_1.newValue, card_2: card_2.newValue },
      review_card: cleanRelationFields(pageData.review_card),
      sources: pageData.sources?.map(item => {
        delete item.id;
        return item;
      }),
      publishing: {
        ...pageData.publishing,
        permalink: updatedPermalink,
        breadcrumb: componentsToModify.breadcrumb ? updatedBreadcrumb : pageData.publishing?.breadcrumb
      },
      banner: {
        ...pageData.banner,
        custom_image: cleanRelationFields(pageData.banner?.custom_image),
        title: componentsToModify.banner ? updatedBannerTitle : pageData.banner?.title
      },
      meta: {
        ...pageData.meta,
        title: componentsToModify.meta ? updatedMetaTitle : pageData.meta?.title,
        description: componentsToModify.meta ? updatedMetaDescription : pageData.meta?.description,
        image: componentsToModify.meta && combinedImageId ? combinedImageId : null
      }
    };
  };

  const createCopyPage = async (newPage, index) => {
    const pageConfirmation = {
      replace: itemsToReplicate[index],
      siteLink: config.current.sitePreviewUrl + newPage.publishing.permalink,
      flex_zone: newPage.flex_zone
    };

    await axios.post(`${config.current.strapiUrl}/api/${COLLECTIONS_LIST.STRAPI_CREDIT_FLEX_PAGE.api}`, { data: newPage }, {
      headers: {
        Authorization: `Bearer ${config.current.token}`
      }
    }).then(result =>  {
      pageConfirmation['strapiLink'] = config.current.strapiUrl + COLLECTIONS_LIST.STRAPI_CREDIT_FLEX_PAGE.strapiLink + result.data?.data?.id;
      pageConfirmation['id'] = result.data?.data?.id;
      setResultPages((prev) => [...prev, pageConfirmation]);
    });
  };

  return (
    <Flex flexDirection="column" alignItems="center" h="full">
      <ProgressBar processName="pageDuplication" currentStep={1} />
      <Heading textAlign="center" mb={10}> Select Content Components</Heading>
      <Box w="full" borderWidth="1px" rounded="lg" shadow="1px 1px 3px rgba(0,0,0,0.3)" py={6} px={44}>
        {errorMessage && (
          <Alert status="error" mb={6}>
            <AlertIcon />
            <AlertTitle>Strapi error:</AlertTitle>
            <AlertDescription>
              {errorMessage}
            </AlertDescription>
          </Alert>
        )}
        <SimpleGrid gridTemplateColumns="repeat(3, 1fr)" gridGap={5}>
          <ComponentBox name="all" icon="select" onCheckboxChange={onCheckAllChange} />
          <ComponentBox name="permalink" icon="link" isDefault={true} isSelectedByParent={componentsToModify.permalink} onCheckboxChange={onCheckboxChange} />
          <ComponentBox name="breadcrumb" icon="settings" isSelectedByParent={componentsToModify.breadcrumb} onCheckboxChange={onCheckboxChange} />
          <ComponentBox name="meta" isSelectedByParent={componentsToModify.meta} onCheckboxChange={onCheckboxChange} />
          <ComponentBox name="name" icon="settings" isSelectedByParent={componentsToModify.name} onCheckboxChange={onCheckboxChange} />
          <ComponentBox name="banner" icon="title" isSelectedByParent={componentsToModify.banner} onCheckboxChange={onCheckboxChange} />
        </SimpleGrid>
        <Divider my={6} />
        <Text fontWeight="bold" mb={4}>
          Data found on the template page
        </Text>
        {pageData && (
          <UnorderedList>
            <ListItem><b>Page Name: </b>{pageData.name}</ListItem>
            <ListItem><b>Permalink: </b>{pageData.publishing?.permalink}</ListItem>
            <ListItem><b>Breadcrumb: </b>{pageData.publishing?.breadcrumb}</ListItem>
            <ListItem><b>Banner title: </b>{pageData.banner?.title}</ListItem>
            <ListItem><b>Meta title: </b>{pageData.meta?.title}</ListItem>
            <ListItem><b>Meta description: </b>{pageData.meta?.description}</ListItem>
          </UnorderedList>
        )}
      </Box>
      <Confirmation
        buttonLabel="Create Pages"
        bodyDescription={`Please check template data and selected components to replace before moving on.\n
        Page Name: Yes.\n
        Permalink: Yes.\n
        Breadcrumb: ${componentsToModify.breadcrumb ? 'Yes' : 'No'}.\n
        Banner Title: ${componentsToModify.banner ? 'Yes' : 'No'}\n
        Meta Title and Description: ${componentsToModify.meta ? 'Yes' : 'No'}`}
        onClickEvent={() => handleProcess()}
        shouldBeLoading={isProcessing}
        shouldBeDisabled={false} />
    </Flex>
  );
};
