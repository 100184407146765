'use client'

import { createContext, useState, useEffect, useRef, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';
import { ChakraProvider, theme, SimpleGrid, Box, Flex, Text, useToast } from '@chakra-ui/react';
import { PageNavigation } from './components/PageNavigation';
import { Home } from './pages/Home';
import { SelectFlex } from './pages/duplicator/SelectFlex';
import { StaticComponents } from './pages/duplicator/StaticComponents';
import { DynamicComponents } from './pages/duplicator/DynamicComponents';
import { SelectCCTemplate } from './pages/credit-cards/SelectCCTemplate';
import { StaticCCComponents } from './pages/credit-cards/StaticCCComponents';
import { DynamicCCComponents } from './pages/credit-cards/DynamicCCComponents';
import { CreatedPages } from './pages/CreatedPages';
import { SelectAutofill } from './pages/SelectAutofill';
import { SelectSections } from './pages/SelectSections';
import { SelectRapidRefresh } from './pages/SelectRapidRefresh';
import { SherlockSpreadsheet } from './pages/sherlock/SherlockSpreadsheet';
import { WoodPeckerContacts } from './pages/woodpecker/WoodPeckerContacts';
import { PipeDriveContacts } from './pages/pipedrive/PipeDriveContacts';
import { ConsolidateAsanaTasks } from './pages/asana/ConsolidateAsanaTasks';
import { PAGE_CONFIG } from './utils/constants';
import axios from 'axios';

const GeneralContext = createContext(null);

export const App = ({}) => {
  const config = useRef(PAGE_CONFIG.dev);
  const [accessToken, setAccessToken] = useState<any>(null);
  const [environment, setEnvironment] = useState<string>('dev');
  const [collection, setCollection] = useState<string>('STRAPI_AUTO_OTHER');
  const [operationType, setOperationType] = useState<string>('state');
  const [stateToProcess, setStateToProcess] = useState<string>('Alabama');
  const [cityToProcess, setCityToProcess] = useState<string>(null);
  const [pageData, setPageData] = useState<any>(null);
  const [permalinkStyle, setPermalinkStyle] = useState<string>('complete');
  const [urlPattern, setUrlPattern] = useState<string>('');
  const [itemsToReplicate, setItemsToReplicate] = useState<Array<any>>([]);
  const [resultPages, setResultPages] = useState<Array<any>>([]);
  const [pagesToProcess, setPagesToProcess] = useState<Array<string>>([]);
  const [currentProcess, setCurrentProcess] = useState<string>('pageDuplication');
  const [monthToReplace, setMonthToReplace] = useState<string>('');
  const [currentDateFilter, setCurrentDateFilter] = useState<Date>(new Date());
  const [newMonth, setNewMonth] = useState<string>('');
  const [newDateFilter, setNewDateFilter] = useState<Date>(new Date());
  const [modifyPageDate, setModifyPageDate] = useState<boolean>(false);
  const toast = useToast();

  useEffect(() => {
    checkGoogleTokenExpiration();

    const intervalId = setInterval(() => {
      checkGoogleTokenExpiration();
    }, 60000);
    
    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const logoutAndNotify = (messageContent, messageType: any = 'info') => {
    googleLogout();
    setAccessToken(null);
    localStorage.removeItem('access_token');
    toast({
      title: messageContent,
      status: messageType,
      duration: 3000,
      isClosable: false,
    });
  };

  const checkGoogleTokenExpiration = async () => {
    const token = localStorage.getItem('access_token');

    if (!token) {
      return;
    }
    
    try {
      const response = await axios.get('https://www.googleapis.com/oauth2/v3/tokeninfo', {
        params: {
          access_token: token,
        }
      });
  
      if (response.status === 200) {
        const tokenInfo = response.data;
        //Session will end if there are less than 5 minutes on the expiration time
        if (parseInt(tokenInfo.expires_in, 10) < 300) {
          logoutAndNotify('Session expired');
        } else {
          setAccessToken(token);
        }
      } else {
        throw new Error('Token is not valid');
      }
    } catch (error) {
      logoutAndNotify(`Error checking token: ${error}`, 'error');
    }
  };

  return (
    <GeneralContext.Provider value={{
      config,
      accessToken,
      setAccessToken,
      environment,
      setEnvironment,
      collection,
      setCollection,
      operationType,
      setOperationType,
      stateToProcess,
      setStateToProcess,
      cityToProcess,
      setCityToProcess,
      pageData,
      setPageData,
      permalinkStyle,
      setPermalinkStyle,
      urlPattern,
      setUrlPattern,
      itemsToReplicate,
      setItemsToReplicate,
      resultPages,
      setResultPages,
      pagesToProcess,
      setPagesToProcess,
      currentProcess,
      setCurrentProcess,
      monthToReplace,
      setMonthToReplace,
      currentDateFilter,
      setCurrentDateFilter,
      newMonth,
      setNewMonth,
      newDateFilter,
      setNewDateFilter,
      modifyPageDate,
      setModifyPageDate
      }}>
      <ChakraProvider theme={theme}>
        <SimpleGrid
          h="100vh"
          w="full"
          templateColumns={{ base: 'auto', lg: '20% auto' }} 
          templateRows={{ base: '80px auto', lg: 'auto' }}
        >
          <Router>
            <PageNavigation />
            <Box p={5}>
              {accessToken ? (
                <Routes>
                  <Route path="*" element={<Home />} />
                  <Route path="/duplicator/flex" element={<SelectFlex />} />
                  <Route path="/components/static" element={<StaticComponents />} />
                  <Route path="/components/dynamic" element={<DynamicComponents />} />
                  <Route path="/duplicator/template/cc" element={<SelectCCTemplate />} />
                  <Route path="/components/static/cc" element={<StaticCCComponents />} />
                  <Route path="/components/dynamic/cc" element={<DynamicCCComponents />} />
                  <Route path="/created" element={<CreatedPages />} />
                  <Route path="/autofill" element={<SelectAutofill />} />
                  <Route path="/sections" element={<SelectSections />} />
                  <Route path="/refresh" element={<SelectRapidRefresh />} />
                  <Route path="/sherlock" element={<SherlockSpreadsheet />} />
                  <Route path="/woodpecker" element={<WoodPeckerContacts />} />
                  <Route path="/pipedrive" element={<PipeDriveContacts />} />
                  <Route path="/asana" element={<ConsolidateAsanaTasks />} />
                </Routes>
              ) : ( // just show a generic message if the user is not logged in
                <Flex alignItems="center" justifyContent="center" h="full">
                  <Text color="gray.500" fontSize="2xl">
                    This site requires a login to interact with the tools.
                  </Text>
                </Flex>
              )}
            </Box>
          </Router>
        </SimpleGrid>
      </ChakraProvider>
    </GeneralContext.Provider>
  );
};

export const useGeneralContext = () => useContext(GeneralContext);
