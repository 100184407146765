'use client'

import { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import {
  Button,
  Heading,
  Flex,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  FormControl,
  FormLabel,
  Input,
  Text,
  Badge,
  Icon,
  Progress,
  Switch,
  UnorderedList,
  ListItem
} from '@chakra-ui/react';
import { FiArrowLeft, FiArrowRight, FiRotateCw } from 'react-icons/fi';
import { WiStars } from 'react-icons/wi';
import { useGeneralContext } from '../../App';
import { getErrorMessage, getSpreadsheetId, arrayToCSV } from '../../utils/utils';
import { PROCESS_STATUS_LIST } from '../../utils/constants';
import { ASANA_PAT } from '../../utils/constants';

const ASANA_CONFIG = {
  project_gid: '1161035101067777', // Contact collection queue
  link_gid: '1161868718493371',   // 'Link to Completed Contact Sheet' custom field
  responsible_gid: '1162738680574956', // Responsible custom field
  section_gid: '1161035101067783', // Done column
  next_section_gid: '1161035101067783' // Loaded to momailer column
};

export const ConsolidateAsanaTasks = () => {
  const [asanaTaskId, setAsanaTaskId] = useState<string>('');
  const [addAssetUrl, setAddAssetUrl] = useState<boolean>(true);
  const [assetUrl, setAssetUrl] = useState<string>('');
  const [filterDoneTasks, setFilterDoneTasks] = useState<boolean>(true);
  
  const [asanaSubtasks, setAsanaSubtasks] = useState<Array<any>>([]);
  const [spreadsheetsList, setSpreadsheetsList] = useState<Array<any>>([]);
  const [allData, setAllData] = useState<Array<any>>([]);
  const [configData, setConfigData] = useState<any>({});
  const [currentStep, setCurrentStep] = useState(0);
  const [processLog, setProcessLog] = useState<Array<any>>([]);
  const [processResults, setProcessResults] = useState<any>({
    total: 0
  });

  const [isReadyToProcess, setIsReadyToProcess] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [waitingOnStep, setWaitingOnStep] = useState<string>('');
  const [hasFinished, setHasFinished] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { accessToken } = useGeneralContext();

  useEffect(() => {
    setIsReadyToProcess(Boolean(asanaTaskId && ((addAssetUrl && assetUrl) || (!addAssetUrl))));
  }, [asanaTaskId, addAssetUrl, assetUrl]);

  useEffect(() => {
    if (waitingOnStep && !Boolean(processResults.missingColumns?.length)) {
      resumeStep();
    }
  }, [waitingOnStep]);

  useEffect(() => {
    if (isProcessing && !errorMessage && currentStep < steps.length) {
      executeStep();
    } else if (currentStep >= steps.length) {
      const timeout = setTimeout(() => {
        setIsProcessing(false);
        setHasFinished(true);
      }, 1000);
  
      return () => clearTimeout(timeout);
    }
  }, [isProcessing, errorMessage, currentStep]);

  const returnProcess = () => {
    setCurrentStep(0);
    setIsReadyToProcess(true);
    setIsProcessing(false);
    setHasFinished(false);
    setErrorMessage('');
    setProcessLog([]);
    setProcessResults({
      subtasks: 0
    });
  };

  const resetProcess = () => {
    setAsanaTaskId('');
    setAddAssetUrl(true);
    setAssetUrl('');
    setCurrentStep(0);
    setIsReadyToProcess(false);
    setIsProcessing(false);
    setHasFinished(false);
    setErrorMessage('');
    setProcessLog([]);
    setProcessResults({
      subtasks: 0
    });
  };

  const startStep = (stepName) => setProcessLog(prev => prev.concat({
    step: stepName,
    hasFinished: false,
    status: 'In execution'
  }));

  const completeStep = (stepName) => setProcessLog(prev => prev.map(item => {
    if (item.step === stepName) {
      item.hasFinished = true;
      item.status = 'Success';
    }

    return item;
  }));

  const markErrorOnStep = (stepName) => setProcessLog(prev => prev.map(item => {
    if (item.step === stepName) {
      item.hasFinished = true;
      item.status = 'Error';
    }

    return item;
  }));

  const resumeStep = () => {
    completeStep(waitingOnStep);
    setCurrentStep(prev => prev + 1);
    setWaitingOnStep('');
    setProcessResults(prev => {
      delete prev.missingColumns;
      return prev;
    });
  }

  const executeStep = async () => {
    const step: any = steps[currentStep];

    if (step.shouldSkip) {
      return;
    }

    try {
      startStep(step.name);
      // Execute the current step function
      await step.call();
      // Move to the next step
      if (step.waitForUser) {
        setWaitingOnStep(step.name);
      } else {
        completeStep(step.name);
        setCurrentStep(prev => prev + 1);
      }
    } catch (error) {
      setErrorMessage(error.message || error);
      markErrorOnStep(step.name);
    }
  };

  const getSubtasks = async () => {
    try {
      // Make API request to Asana for the subtasks list
      const request = await axios.get(`https://app.asana.com/api/1.0/tasks/${asanaTaskId}/subtasks`, {
        headers: {
          'Authorization': `Bearer ${ASANA_PAT}`
        },
        params: {
          opt_fields: 'parent.name,name,completed,gid,resource_type,custom_fields, memberships.section.gid,memberships.section.name'
        }
      });
  
      if (request && request.status === 200 && Boolean(request.data?.data?.length)) {
        const subtasksList = request.data.data;
        const parentTask = subtasksList[0].parent;
        const currentDate = dayjs().format('YYYY-MM-DD');
        const doneTasks = subtasksList.filter(item => {
          if (Boolean(item.memberships.length)) {
            const column = item.memberships[0];
            return column.section?.name === 'Done';
          }

          return false;
        });

        setAsanaSubtasks(filterDoneTasks ? doneTasks : subtasksList);
        setProcessResults(prev => ({
          ...prev,
          allSubtasks: subtasksList.length,
          doneSubtasks: doneTasks.length
        }));
        setConfigData(prev => ({
          ...prev,
          parentTaskName: parentTask.name,
          moMailerFileName: `${parentTask.name}_${currentDate}`
        }));
      } else {
        throw new Error('No available subtasks.');
      }
    } catch (error) {
      throw new Error(`Asana process error. ${getErrorMessage(error)}`);
    }
  };

  const getSpreadsheets = async () => {
    try {
      const spreadsheetsData = [];
      const rejectedSubtasks = [];
      const spreadsheets = asanaSubtasks.slice(-2).reduce((acc: Array<any>, curr: any) => {
        const link = curr.custom_fields.find(item => item.gid === ASANA_CONFIG.link_gid);
        const responsible = curr.custom_fields.find(item => item.gid === ASANA_CONFIG.responsible_gid);
        
        if (link?.text_value) {
          acc.push({ link: link.text_value, responsible: responsible?.text_value || '', name: curr.name});
        } else {
          rejectedSubtasks.push(curr);
        }

        return acc;
      }, []);

      for (const item of spreadsheets) {
        const result = await getGooglesheetData(item);
        spreadsheetsData.push(result);
      }

      setSpreadsheetsList(spreadsheetsData);
      setProcessResults(prev => ({
        ...prev,
        rejectedSubtasks: rejectedSubtasks.length
      }));
    } catch (error) {
      throw new Error(`Asana process error. ${getErrorMessage(error)}`);
    }
  };

  const getGooglesheetData = async (sheet) => {
    try {
      const spreadsheetId = getSpreadsheetId(sheet.link);
      // Make API request to Google Sheets using the obtained access token
      const fileRequest = await axios.get(`https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      if (fileRequest && fileRequest.status === 200 && Boolean(fileRequest.data?.sheets?.length)) {
        setConfigData(prev => ({
          ...prev,
          file: fileRequest.data.properties
        }));
        const firstSheetName = fileRequest.data.sheets[0].properties.title;
        const dataRequest = await axios.get(`https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${firstSheetName}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });

        if (dataRequest && dataRequest.status === 200 && Boolean(dataRequest.data?.values?.length)) {
          return { ...sheet, columns: dataRequest.data.values[0], values: dataRequest.data.values.slice(1) };
        } else {
          throw new Error('No data available.');
        }
      } else {
        throw new Error('The source is not avaible for fetching.');
      }
    } catch (error) {
      throw new Error(`Loading data from the spreadsheet. ${getErrorMessage(error)}`);
    }
  };

  const checkRequiredColumns = () => {
    try {
      const results = [];
      const spreadsheets = [... spreadsheetsList];
      for (const item of spreadsheets) {
        const columns = item.columns;
        const missingColumns = [];
        if (!columns.includes('contact_email')) {
          missingColumns.push('contact_email');
        }
        if (!columns.includes('contact_name')) {
          missingColumns.push('contact_name');
        }
        if (!columns.includes('SourceURL')) {
          if (columns.includes('Source URL')) {
            const replaceIndex = columns.findIndex(item => item === 'Source URL');
            columns[replaceIndex] = 'SourceURL';
            item.columns = columns;
          } else {
            missingColumns.push('SourceURL');
          }
        }
        if (!columns.includes('contact_title')) {
          missingColumns.push('contact_title');
        }
        if (!columns.includes('Notes/Comments/Instructions')) {
          missingColumns.push('Notes/Comments/Instructions');
        }
        if (Boolean(missingColumns.length)) {
          results.push(`${item.name} is missing the following columns: ${missingColumns.join(', ')}.`);
        }
      }
      setSpreadsheetsList(spreadsheets);
      setProcessResults(prev => ({
        ...prev,
        missingColumns: results
      }));
    } catch (error) {
      throw error;
    }
  };

  const checkFileFormat = async () => {
    try {
      const groupedData = [];
      const filesData = await Promise.all([...spreadsheetsList].map(item => new Promise((res) => res(createObject(item)))));
      const commonColumns: any = filesData.reduce((columns: any, file: any) => {
        // Comparing only with the first row from each file becase the entire array has the same structure
        return columns.filter(key => key in file[0]);
      }, Object.keys(filesData[0][0]));
      // Filter files containing contact_email column
      const validFiles: any = [...filesData].filter(item => 'contact_email' in item[0]);
      
      for (const item of validFiles) {
        const filteredData = item.map(row => commonColumns.reduce((newRow, column) => {
          const value = row[column].trim();
          newRow[column] = column === 'contact_name' && !value ? 'there' : value;

          return newRow;
        }, {}));
        groupedData.push(...filteredData);
      }

      setAllData(groupedData);
    } catch (error) {
      throw error;
    }
  };

  const createObject = (data) => {
    const { name, responsible, columns, values } = data;
    return values.map(row => columns.reduce((acc: any, curr: any, index) => {
      acc[curr] = row[index] || '';

      if (addAssetUrl) {
        acc.asset_url = assetUrl;
      }
  
      return {
        ...acc,
        assignment_id: name,
        responsible_va: responsible
      };
    }, {}));
  };

  const processContactColumns = () => {
    try {
      const data = [...allData];
      const columns = Object.keys(allData[0]);
      // Compile regex pattern to match the relevant columns
      const searchColumnsRegex = /(contact_coverage_area|survey|ranking)/i;
      
      // Find relevant columns based on the pattern
      const relevantColumns = {};
      columns.forEach(col => {
        const match = col.match(searchColumnsRegex);
        if (match) {
          relevantColumns[col] = match[0];
        }
      });

      if (Boolean(Object.keys(relevantColumns).length)) {
        const contactValue = Object.keys(relevantColumns).join(', ');
        for (const col in relevantColumns) {
          data.forEach(row => {
            row.contact_coverage_area = contactValue.replace(/( ,)+|(^,)|(,\s*$)/g, '');
            // If the column is 'ranking' or 'survey', apply specific mappings
            if (['ranking', 'survey'].includes(relevantColumns[col])) {
              row[col] = row[col] === '1' ? relevantColumns[col] : '';
            }
          });
        }
      }
    } catch (error) {
      throw error;
    }
  };

  const downloadMoMailerFile = () => {
    const data = allData.filter(item => item.contact_email);
    const customColumnsOrder = [
      'contact_email',
      'contact_name',
      'contact_family_name (last name)',
      'contact_family_name',
      'contact_twitter',
      'contact_coverage_area',
      'source_domain',
      'source_url',
      'SourceURL',
      'campaign',
      'Notes/Comments/Instructions',
      'Notes/Comments',
      'assignment_id',
      'asset_url'
    ];
    const csv = arrayToCSV(data, customColumnsOrder);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', configData.moMailerFileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const moveTicketToDoneColumn = async () => {
    try {
      for (const item of asanaSubtasks) {
        await addTaskToAsanaSection(item.gid);
        await addCommentToAsanaTask(item.gid);
        await updateAsanaTask(item.gid);
      }
    } catch (error) {
      throw error;
    }
  };

  const addTaskToAsanaSection = async (gid) => {
    try {
      const requestBody = { data: { task: gid } };
      await axios.post(`https://app.asana.com/api/1.0/sections/${ASANA_CONFIG.next_section_gid}/addTask`, requestBody, {
        headers: {
          'Authorization': `Bearer ${ASANA_PAT}`
        }
      });
    } catch (error) {
      throw new Error(`Asana process error. ${getErrorMessage(error)}`);
    }
  };

  const addCommentToAsanaTask = async (gid) => {
    try {
      const requestBody = { data: { text: `I was consolidated into a file called ${configData.moMailerFileName}` } };
      await axios.post(`https://app.asana.com/api/1.0/tasks/${gid}/stories`, requestBody, {
        headers: {
          'Authorization': `Bearer ${ASANA_PAT}`
        }
      });
    } catch (error) {
      throw new Error(`Asana process error. ${getErrorMessage(error)}`);
    }
  };

  const updateAsanaTask = async (gid) => {
    try {
      const requestBody = { data: { completed: true } };
      await axios.put(`https://app.asana.com/api/1.0/tasks/${gid}`, requestBody, {
        headers: {
          'Authorization': `Bearer ${ASANA_PAT}`
        }
      });
    } catch (error) {
      throw new Error(`Asana process error. ${getErrorMessage(error)}`);
    }
  };

  const steps = [
    { name: 'Get all subtasks', call: () => getSubtasks() },
    { name: 'Get all spreadsheets', call: () => getSpreadsheets() },
    { name: 'Search for required columns', waitForUser: true, call: () => checkRequiredColumns() },
    { name: 'Validating file format', call: () => checkFileFormat() },
    { name: 'Process contact columns', call: () => processContactColumns() },
    { name: 'Create MoMailer file', call: () => downloadMoMailerFile() },
    { name: 'Move tasks to done column', shouldSkip: !filterDoneTasks, call: () => moveTicketToDoneColumn() }
  ];

  return (
    <Flex flexDirection="column" alignItems="center" h="full">
      <Heading textAlign="center" mb={10}>Asana Pick Up For MoMailer</Heading>
      <Text mb={8}>This process allows you to consolidate multiple files from Asana tasks.</Text>
      <Box w="full" borderWidth="1px" rounded="lg" shadow="1px 1px 3px rgba(0,0,0,0.3)" p={8}>
        {!isProcessing && !hasFinished && (
          <Flex background="#F6F8FF" borderRadius="8px" justifyContent="center" py={8}>
            <FormControl w="600px">
              <FormLabel htmlFor="asana-task" mb={1}>
                Parent Task Asana ID
              </FormLabel>
              <Input
                id="asana-task"
                value={asanaTaskId}
                background="white"
                onChange={(e) => setAsanaTaskId(e.target.value)}
                mb={4}
              />
              <Flex alignItems="center" mb={4}>
                <FormLabel htmlFor="add-asset-url" mb={0}>
                  Add Asset URL?
                </FormLabel>
                <Switch id="add-asset-url" isChecked={addAssetUrl} onChange={e => setAddAssetUrl(e.target.checked)} />
              </Flex>
              {addAssetUrl && (<>
                <FormLabel htmlFor="asset-url" mb={1}>
                  Asset URL
                </FormLabel>
                <Input
                  id="asset-url"
                  value={assetUrl}
                  background="white"
                  onChange={(e) => setAssetUrl(e.target.value)}
                  mb={4}
                />
              </>)}
              <Flex alignItems="center" mb={4}>
                <FormLabel htmlFor="filter-done-tasks" mb={0}>
                  Only Gather 'Done' Tasks?
                </FormLabel>
                <Switch id="filter-done-tasks" isChecked={filterDoneTasks} onChange={e => setFilterDoneTasks(e.target.checked)} />
              </Flex>
              <Flex justifyContent="center" mt={8}>
                <Button
                  w="150px"
                  variant="solid"
                  colorScheme="blue"
                  isDisabled={!isReadyToProcess}
                  isLoading={isProcessing}
                  onClick={() => setIsProcessing(true)}>
                  Process
                  <Icon boxSize={8} ml={1} as={WiStars} />
                </Button>
              </Flex>
            </FormControl>
          </Flex>
        )}
        {isProcessing && !hasFinished && Boolean(processLog.length) && (<>
          <Heading as="h3" size="md" textAlign="center" mb={6}>Progress</Heading>
          <div>
            {processLog.map((item, index) => (
              <Flex key={`progress-list-${index}`} px="15%" alignItems="center" w="100%" gap="5%" mb={index < processLog.length - 1 ? 6 : 0}>
                <Text w="30%">{`Step ${index + 1}: ${item.step}`}</Text>
                <Progress
                  w="45%"
                  borderRadius="5px"
                  isIndeterminate={!item.hasFinished}
                  value={item.hasFinished ? 100 : 0} />
                <Badge
                  colorScheme={PROCESS_STATUS_LIST[item.status]}
                  borderRadius="5px"
                  textAlign="center"
                  py={1}
                  px={2}
                  w="15%">
                  {item.status}
                </Badge>
              </Flex>
            ))}
          </div>
          {processResults.missingColumns && (
            <Box w="auto" mt={8} mx="15%">
              <Alert status="warning" display="block">
                <Flex justifyContent="center" mb={1}>
                  <AlertIcon />
                  <AlertTitle>Warning</AlertTitle>
                </Flex>
                <AlertDescription>
                  <UnorderedList>
                    {processResults.missingColumns.map((item, index) => <ListItem key={`missing-columns-item-${index}`}>{item}</ListItem>)}
                  </UnorderedList>
                </AlertDescription>
              </Alert>
              <Flex mt={8} justifyContent="center" gap={4}>
                <Button
                  w="150px"
                  variant="outline"
                  colorScheme="blue"
                  onClick={() => resetProcess()}>
                  <Icon boxSize={4} mr={1} as={FiRotateCw} />
                  Start again
                </Button>
                <Button
                  w="150px"
                  variant="outline"
                  colorScheme="blue"
                  onClick={() => resumeStep()}>
                  <Icon boxSize={4} mr={2} as={FiArrowRight} />
                  Continue
                </Button>
              </Flex>
            </Box>
          )}
          {errorMessage && (
            <Box w="auto" mt={8} mx="15%">
              <Alert status="error">
                <AlertIcon />
                <AlertTitle>Error:</AlertTitle>
                <AlertDescription>
                  {errorMessage}
                </AlertDescription>
              </Alert>
              <Flex mt={8} justifyContent="center" gap={4}>
                <Button
                  w="150px"
                  variant="outline"
                  colorScheme="blue"
                  onClick={() => returnProcess()}>
                  <Icon boxSize={4} mr={1} as={FiArrowLeft} />
                  Back
                </Button>
                <Button
                  w="150px"
                  variant="outline"
                  colorScheme="blue"
                  onClick={() => resetProcess()}>
                  <Icon boxSize={4} mr={2} as={FiRotateCw} />
                  Start again
                </Button>
              </Flex>
            </Box>
          )}
        </>)}     
        {!isProcessing && hasFinished && (
          <Flex flexDirection="column" alignItems="center">
            <Heading as="h3" size="lg" color="#57C600" mb={6}>Success</Heading>
            <Text mb={2}>The consolidated file was generated and all subtasks were moved to the Done column.</Text>
            <Text fontWeight="bold" mb={2}>{`Asana parent task name: ${configData.moMailerFileName}`}</Text>
            <Flex w="full" mt={3} mb={8} justifyContent="center" gap={4} flexWrap="wrap">
              <Badge p="8px 12px">{`Subtasks total: ${processResults.allSubtasks}`}</Badge>
              <Badge colorScheme="blue" p="8px 12px">{`Done subtasks: ${processResults.doneSubtasks}`}</Badge>
              <Badge colorScheme="red" p="8px 12px">{`Subtasks without spreadsheet: ${processResults.rejectedSubtasks}`}</Badge>
            </Flex>
            <Button
              w="150px"
              variant="outline"
              colorScheme="blue"
              onClick={() => resetProcess()}>
              <Icon boxSize={4} mr={2} as={FiRotateCw} />
              Start again
            </Button>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};
