'use client'

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Button,
  Heading,
  Flex,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Link,
  Icon,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Checkbox,
  FormControl,
  FormLabel,
  Divider,
  NumberInput,
  NumberInputField,
  Select,
  RadioGroup,
  Radio,
  Stack
} from '@chakra-ui/react';
import { FiExternalLink } from 'react-icons/fi';
import { useGeneralContext } from '../App';
import { STATES_LIST, COLLECTIONS_LIST, MONTHS_LIST, PAGE_CONFIG } from '../utils/constants';
import {
  replaceIgnoreCase,
  replaceMultipleStrings,
  cleanRelationFields,
  replaceOnFlexComponent,
  getUpdatedDbData,
  getUpdatedWinnerCompany,
  formatDate,
  findMatchingObject
} from '../utils/utils';
import { ProgressBar } from '../components/ProgressBar';
import { Confirmation } from '../components/Confirmation';

export const SelectRapidRefresh = () => {
  const [pageId, setPageId] = useState<string>('');
  const [similarPages, setSimilarPages] = useState<Array<any>>([]);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isEmptyResult, setIsEmptyResult] = useState<boolean>(false);
  const [isReadyToProcess, setIsReadyToProcess] = useState<boolean>(false);
  const [showDataErrorMessage, setShowDataErrorMessage] = useState<boolean>(false);
  const [showProcessErrorMessage, setShowProcessErrorMessage] = useState<boolean>(false);

  const {
    config,
    environment,
    setEnvironment,
    collection,
    setCollection,
    stateToProcess,
    setStateToProcess,
    pageData,
    setPageData,
    setPagesToProcess,
    setResultPages,
    setCurrentProcess,
    monthToReplace,
    setMonthToReplace,
    currentDateFilter,
    setCurrentDateFilter,
    newMonth,
    setNewMonth,
    newDateFilter,
    setNewDateFilter
  } = useGeneralContext();
  const navigate = useNavigate();

  useEffect(() => {
    setEnvironment('dev');
    setCollection('STRAPI_AUTO_OTHER');
    setStateToProcess('Alabama');
    setPageId('');
    setCurrentProcess('rapidRefresh');
    setDateValues();
  }, []);

  useEffect(() => {
    config.current = PAGE_CONFIG[environment];
  }, [environment]);

  useEffect(() => {
    setPageData(null);
    setShowDataErrorMessage(false);
    setSimilarPages([]);
    setPagesToProcess([]);
    setResultPages([]);
    setIsEmptyResult(false);
  }, [environment, collection, pageId, stateToProcess]);

  useEffect(() => {
    setIsReadyToProcess(similarPages.some(item => item.isChecked));
  }, [similarPages]);

  const setDateValues = () => {
    const currentDate = new Date();
    const nextMonth = new Date();
    nextMonth.setMonth(currentDate.getMonth() + 1);
    setMonthToReplace(currentDate.toLocaleString('en-US', { month: 'long' }));
    setNewMonth(nextMonth.toLocaleString('en-US', { month: 'long' }));

    currentDate.setDate(1);
    currentDate.setMonth(currentDate.getMonth() - 2);
    setCurrentDateFilter(currentDate);

    nextMonth.setDate(1);
    nextMonth.setMonth(nextMonth.getMonth() - 2);
    setNewDateFilter(nextMonth);
  };

  const updateTableItems = (itemId, newCheckedValue) => {
    setSimilarPages(prev => prev.map(item => item.id === itemId ? { ...item, isChecked: newCheckedValue } : item));
  };

  const confirmSelectedPages = async () => {
    setIsProcessing(true);
    setShowProcessErrorMessage(false);
    try {
      const selectedPages = similarPages.filter(item => item.isChecked);
      const updatedPages = await Promise.all(selectedPages.map(page => new Promise((res) => res(refreshPage(page)))));
      await Promise.all(updatedPages.map(page => new Promise((res) => res(createRefreshPage(page)))));
    } catch {
      setShowProcessErrorMessage(true);
    } finally {
      setIsProcessing(false);
      navigate('/created');
    }
  };

  const refreshPage = async (page) => {
    const cleanFlexZone = cleanRelationFields([...page.flex_zone]);
    const updatedFlexZone = await Promise.all(cleanFlexZone.map(component => new Promise((res) => res(refreshComponent(component)))));

    return {
      ...page,
      publishedAt: null,
      name: replaceIgnoreCase(page.name, monthToReplace, newMonth),
      authors: cleanRelationFields(page.authors),
      experts: cleanRelationFields(page.experts),
      reviewed_by: cleanRelationFields(page.reviewed_by),
      verified_by: cleanRelationFields(page.verified_by),
      kevel: cleanRelationFields(page.kevel),
      editor: cleanRelationFields(page.editor),
      flex_zone: updatedFlexZone,
      syndication: cleanRelationFields(page.syndication),
      publishing: {
        ...page.publishing,
        permalink: `${page.publishing.permalink.slice(0, -1)}-${newMonth.toLowerCase()}/`,
        breadcrumb: replaceIgnoreCase(page.publishing.breadcrumb, monthToReplace, newMonth)
      },
      banner: {
        ...page.banner,
        title: replaceIgnoreCase(page.banner.title, monthToReplace, newMonth),
        subtitle: replaceIgnoreCase(page.banner.subtitle, monthToReplace, newMonth),
        description: replaceIgnoreCase(page.banner.description, monthToReplace, newMonth)
      },
      meta: {
        ...page.meta,
        title: replaceIgnoreCase(page.meta.title, monthToReplace, newMonth),
        description: replaceIgnoreCase(page.meta.description, monthToReplace, newMonth),
        image: cleanRelationFields(page.meta?.image)
      }
    };
  };

  const refreshComponent = async (component) => {
    const updatedComponent = { ...component };
    delete updatedComponent.id;

    if (component['__component'] === 'database.db-query-table') {
      const jsonData = JSON.parse(component.playground);
      const dateReplacements = {
        [formatDate(currentDateFilter)]: formatDate(newDateFilter),
        [formatDate(currentDateFilter, 'DD-MM-YYYY')]: formatDate(newDateFilter, 'DD-MM-YYYY'),
        [formatDate(currentDateFilter, 'MM-DD-YYYY')]: formatDate(newDateFilter, 'MM-DD-YYYY')
      };
      const newQuery = replaceMultipleStrings(jsonData.query, dateReplacements);
      const newDataset = await getUpdatedDbData(newQuery, config.current);
      updatedComponent.playground = JSON.stringify({ ...jsonData, query: newQuery, dataset: newDataset });
      updatedComponent.winner_company = await getUpdatedWinnerCompany({
        data: newDataset,
        mainColumn: jsonData.sortColumn,
        referenceColumn: findMatchingObject(jsonData.columnsConfig, item => ['Company', 'company'].includes(item.display_text)),
        filters: jsonData.defaults,
        config: config.current
      });
    }

    return replaceOnFlexComponent(updatedComponent, monthToReplace, newMonth);
  };

  const createRefreshPage = async (newPage) => {
    const pageConfirmation = {
      replace: newPage.state,
      siteLink: config.current.sitePreviewUrl + newPage.publishing.permalink,
      flex_zone: newPage.flex_zone
    };

    delete newPage.id;
    delete newPage.state;
    delete newPage.isChecked;
    delete newPage.strapiLink;
    delete newPage.siteLink;

    await axios.post(`${config.current.strapiUrl}/api/${COLLECTIONS_LIST[collection].api}`, { data: newPage }, {
      headers: {
        Authorization: `Bearer ${config.current.token}`
      }
    }).then(result =>  {
      pageConfirmation['strapiLink'] = config.current.strapiUrl + COLLECTIONS_LIST[collection].strapiLink + result.data?.data?.id;
      pageConfirmation['id'] = result.data?.data?.id;
      setResultPages((prev) => [...prev, pageConfirmation]);
    });
  };
  
  const handlePageSubmit = async () => {
    setIsProcessing(true);
    setSimilarPages([]);
    try {
      await axios.get(`${config.current.strapiUrl}/api/${COLLECTIONS_LIST[collection].api}/${pageId}?populate=deep,2`, {
        headers: {
          Authorization: `Bearer ${config.current.token}`
        }
      }).then(async response => {
        const templateData = response.data?.data?.attributes;
        if (!templateData || !templateData.publishing.permalink.includes(stateToProcess.replaceAll(" ", "-").toLowerCase())) {
          setPageData(null);
          setShowDataErrorMessage(true);
        } else {
          const statePages = await Promise.all(Object.keys(STATES_LIST).map(state => new Promise((res) => res(checkForCopyPage(templateData, state)))));
          const formattedPages = [].concat(...statePages);
          const filteredPages = formattedPages.filter(item => item && item['id']);
          setSimilarPages(filteredPages);
          setPageData(templateData);
          setIsEmptyResult(Boolean(!filteredPages.length));
        }
      });
    } catch {
      setShowDataErrorMessage(true);
    } finally {
      setIsProcessing(false);
    }
  };

  const checkForCopyPage = async (templateData, state) => {
    try {
      const originalFormattedState = stateToProcess.replaceAll(' ', '-').toLowerCase();
      const newFormattedState = state.replaceAll(' ', '-').toLowerCase();
      const query = qs.stringify({
        publicationState: 'preview',
        populate: 'deep,6',
        filters: {
          publishing: {
            permalink: {
              $eq: replaceIgnoreCase(templateData.publishing.permalink, originalFormattedState, newFormattedState),
            }
          },
        },
      }, {
        encodeValuesOnly: true
      });
      const url = `${config.current.strapiUrl}/api/${COLLECTIONS_LIST[collection].api}?${query}`
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${config.current.token}`
        }
      });

      if (response?.data?.data?.length > 0) {
        return response.data.data.map(strapiData => (
          {
            ...strapiData.attributes,
            id: strapiData.id,
            state: state,
            isChecked: false,
            strapiLink: config.current.strapiUrl + COLLECTIONS_LIST[collection].strapiLink + strapiData.id,
            siteLink: config.current.sitePreviewUrl + strapiData.attributes.publishing.permalink
          }
        ));
      } else {
        return null;
      }
    } catch {
      return null;
    }
  };

  //TODO: Add pagination to rapid refresh and smart autofill tables
  const DuplicatePagesTable = () => (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th></Th>
            <Th>Strapi CMS Link</Th>
            <Th>State</Th>
            <Th>MoneyGeek Link</Th>
          </Tr>
        </Thead>
        <Tbody>
          {similarPages.map((item, index) => (
            <Tr key={`pages-list${index}`} bg={item.isChecked ? 'blue.100' : 'transparent'}>
              <Td>
                <Checkbox
                  key={`copy-page-checkbox-${index}`}
                  id={`copy-page-checkbox-${index}`}
                  isChecked={item.isChecked}
                  onChange={(e) => updateTableItems(item.id, e.target.checked)} />
              </Td>
              <Td>
                {!item.id ? 'Failed' : (
                  <Link display="flex" alignItems="center" gap={1} href={item.strapiLink} isExternal>
                    <b>{item.id}</b> <Icon as={FiExternalLink} />
                  </Link>
                )}
              </Td>
              <Td>{item.state}</Td>
              <Td>
                {!item.id ? 'No link available' : (
                  <Link display="flex" alignItems="center" gap={1} href={item.siteLink} isExternal>
                    Go to MoneyGeek page <Icon as={FiExternalLink} />
                  </Link>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );

  return (
    <Flex flexDirection="column" alignItems="center" h="full">
      <ProgressBar processName="rapidRefresh" currentStep={0} />
      <Heading textAlign="center" mb={10}>Rapid Refresh</Heading>
      <Box w="full" borderWidth="1px" rounded="lg" shadow="1px 1px 3px rgba(0,0,0,0.3)" p={6}>
        <FormControl>
          <Flex alignItems="flex-start" justifyContent="center" gap="5%" mb={6} h="68px">
            <Box>
              <FormLabel htmlFor="current-month" mb={1}>
                Month to replace
              </FormLabel>
              <Select id="current-month" value={monthToReplace} onChange={(e) => setMonthToReplace(e.target.value)}>
                {MONTHS_LIST.map((item, index) => <option key={`replace-month-${index}`} value={item}>
                  {item}
                </option>)}
              </Select>
            </Box>
            <Box>
              <FormLabel htmlFor="current-date" mb={1}>
                Current date filter value
              </FormLabel>
              <DatePicker
                id="current-date"
                dateFormat="yyyy-MM-dd"
                selected={currentDateFilter}
                onChange={(date) => setCurrentDateFilter(date)} />
            </Box>
            <Divider orientation="vertical" />
            <Box>
              <FormLabel htmlFor="new-month" mb={1}>
                New month value
              </FormLabel>
              <Select id="new-month" value={newMonth} onChange={(e) => setNewMonth(e.target.value)}>
                {MONTHS_LIST.map((item, index) => <option key={`new-month-${index}`} value={item}>
                  {item}
                </option>)}
              </Select>
            </Box>
            <Box>
              <FormLabel htmlFor="new-date" mb={1}>
                New date filter value
              </FormLabel>
              <DatePicker
                id="new-date"
                dateFormat="yyyy-MM-dd"
                selected={newDateFilter}
                onChange={(date) => setNewDateFilter(date)} />
            </Box>
          </Flex>
          <Divider mb={6} />
          <Flex mb={6}>
            <FormLabel htmlFor="environment" mb="0">
              Please select the environment you want to work on:
            </FormLabel>
            <RadioGroup
              id="environment"
              defaultValue={environment}
              onChange={(event) => setEnvironment(event)}>
              <Stack direction="row" gap={3}>
                <Radio value="dev">Development</Radio>
                <Radio value="prod">Production</Radio>
              </Stack>
            </RadioGroup>
          </Flex>
          <Flex alignItems="flex-end" justifyContent="space-between" gap="5%" mb={6}>
            <Box w="30%">
              <FormLabel htmlFor="collection" mb={1}>
                Collection Name
              </FormLabel>
              <Select id="collection" value={collection} onChange={(e) => setCollection(e.target.value)}>
                {Object.keys(COLLECTIONS_LIST).map((item, index) => <option key={`collection-${index}`} value={item}>
                  {COLLECTIONS_LIST[item].name}
                </option>)}
              </Select>
            </Box>
            <Box w="15%">
              <FormLabel htmlFor="page-id" mb={1}>
                Page ID
              </FormLabel>
              <NumberInput id="page-id" value={pageId} min={1} onChange={(e) => setPageId(e)}>
                <NumberInputField />
              </NumberInput>
            </Box>
            <Box w="20%">
              <FormLabel htmlFor="selected-state" mb={1}>
                Template Page State
              </FormLabel>
              <Select id="selected-state" value={stateToProcess} onChange={(e) => setStateToProcess(e.target.value)}>
                {Object.keys(STATES_LIST).map((state, index) => <option key={`state-list-${index}`} value={state}>{state}</option>)}
              </Select>
            </Box>
            <Button
              w="20%"
              variant="solid"
              colorScheme="gray"
              isLoading={!isReadyToProcess && isProcessing}
              onClick={() => handlePageSubmit()}>
              Confirm Page
            </Button>
          </Flex>
        </FormControl>
        {showDataErrorMessage && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>Data error:</AlertTitle>
            <AlertDescription>
              {`It was not possible to identify the page #${pageId} for the collection: ${COLLECTIONS_LIST[collection].name}.
                Please check collection name, ID, and selected state.`}
            </AlertDescription>
          </Alert>
        )}
        {showProcessErrorMessage && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>Process error:</AlertTitle>
            <AlertDescription>
              It was not possible to create the copy pages. Please check all values and execute the process again.
            </AlertDescription>
          </Alert>
        )}
        {isEmptyResult && (
          <Alert status="warning">
            <AlertIcon />
            <AlertTitle>No data:</AlertTitle>
            <AlertDescription>
              {`There are no Strapi pages using a permalink similar to ${pageData?.publishing.permalink}`}
            </AlertDescription>
          </Alert>
        )}
        {similarPages.length > 0 && (<>
          <Divider mb={6} />
          <DuplicatePagesTable />
        </>)}
      </Box>
      <Confirmation
        title="Confirm selected values"
        buttonLabel="Start process"
        bodyDescription={`Please confirm selected pages from the list and dates before moving on:\n
        Month to replace: ${monthToReplace}.\n
        New month value: ${newMonth}.\n
        New date filter value: ${newDateFilter.toLocaleDateString('en-US')}`}
        onClickEvent={() => confirmSelectedPages()}
        shouldBeLoading={isReadyToProcess && isProcessing}
        shouldBeDisabled={!isReadyToProcess} />
    </Flex>
  );
};
